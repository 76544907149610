export const PROFILE_GROUP = [
    'basic',
    'personal',
    'work',
    'photo',
    'family',
    'horoscope',
];

export const MARITAL_STATUS = ['Never Married', 'Widowed', 'Divorced'];

export const EDUCATION = [
    'Bachelors',
    'Masters',
    'Doctor',
    'Doctorate',
    'Diploma',
    'Undergraduate',
    'Postgraduate',
    'Associates degree',
    'Honours degree',
    'Trade school',
    'High school',
    'Less than high school',
    'Chartered Accountant',
];

export const INCOME = [
    'Not working',
    'Upto 1 Lakh',
    '1 Lakh to 2 Lakhs',
    '2 Lakhs to 3 Lakhs',
    '3 Lakhs to 4 Lakhs',
    '4 Lakhs to 5 Lakhs',
    '5 Lakhs to 7 Lakhs',
    '7 Lakhs to 9 Lakhs',
    '9 Lakhs to 12 Lakhs',
    '12 Lakhs to 15 Lakhs',
    '15 Lakhs to 20 Lakhs',
    '20 Lakhs to 30 Lakhs',
    '30 Lakhs to 50 Lakhs',
    '50 Lakhs to 75 Lakhs',
    '75 Lakhs to 1 Crore',
    '1 Crore and above',
    'Not applicable',
    'Dont want to specify',
];

export const PROFESSION = [
    'Private Company',
    'Government / Public Sector',
    'Defense / Civil Services',
    'Business / Self Employed',
    'Non Working',
];

export const STATE = [
    'Andhra Pradesh',
    'Karnataka',
    'Kerala',
    'Maharashtra',
    'Puducherry',
    'Tamil Nadu',
    'Telangana',
    'Others',
];

export const STATE_FILTER_PREF = [
    {
        state: 'Andhra Pradesh',
        pref: [
            'Andhra Pradesh',
            'Karnataka',
            'Kerala',
            'Puducherry',
            'Tamil Nadu',
            'Telangana',
            'Others',
        ],
    },
    {
        state: 'Karnataka',
        pref: [
            'Andhra Pradesh',
            'Karnataka',
            'Kerala',
            'Maharashtra',
            'Puducherry',
            'Tamil Nadu',
            'Telangana',
            'Others',
        ],
    },
    {
        state: 'Kerala',
        pref: [
            'Andhra Pradesh',
            'Karnataka',
            'Kerala',
            'Puducherry',
            'Tamil Nadu',
            'Telangana',
            'Others',
        ],
    },
    { state: 'Maharashtra', pref: ['Karnataka', 'Maharashtra', 'Others'] },
    {
        state: 'Puducherry',
        pref: [
            'Andhra Pradesh',
            'Karnataka',
            'Kerala',
            'Puducherry',
            'Tamil Nadu',
            'Telangana',
            'Others',
        ],
    },
    {
        state: 'Tamil Nadu',
        pref: [
            'Andhra Pradesh',
            'Karnataka',
            'Kerala',
            'Puducherry',
            'Tamil Nadu',
            'Telangana',
            'Others',
        ],
    },
    {
        state: 'Telangana',
        pref: [
            'Andhra Pradesh',
            'Karnataka',
            'Kerala',
            'Puducherry',
            'Tamil Nadu',
            'Telangana',
            'Others',
        ],
    },
    { state: 'Others', pref: [] },
];

export const COMMUNITY = [
    { value: 'Bhavasar Kshatriya', label: 'Bhavasar Kshatriya' },
    { value: 'Kshatriya', label: 'Kshatriya' },
    { value: 'Others', label: 'Others' },
];

export const STAR = [
    { t: 'Aswini', e: 'Ashvini' },
    { t: 'Bharani', e: 'Bharani' },
    { t: 'Karthigai', e: 'Krittika' },
    { t: 'Rohini', e: 'Rohini' },
    { t: 'Mrigasheersham', e: 'Mrigashirsha' },
    { t: 'Thiruvaathirai', e: 'Ardra' },
    { t: 'Punarpoosam', e: 'Punarvasu' },
    { t: 'Poosam', e: 'Pushya' },
    { t: 'Aayilyam', e: 'Ashlesha' },
    { t: 'Makam', e: 'Magha' },
    { t: 'Pooram', e: 'Purva Phalguni' },
    { t: 'Uthiram', e: 'Uttara Phalguni' },
    { t: 'Hastham', e: 'Hasta' },
    { t: 'Chithirai', e: 'Chitra' },
    { t: 'Swaathi', e: 'Swati' },
    { t: 'Visaakam', e: 'Visakha' },
    { t: 'Anusham', e: 'Anuradha' },
    { t: 'Kettai', e: 'Jyeshtha' },
    { t: 'Moolam', e: 'Mula' },
    { t: 'Pooraadam', e: 'Purva Ashadha' },
    { t: 'Uthiraadam', e: 'Uttara Ashadha' },
    { t: 'Thiruvonam', e: 'Shravana' },
    { t: 'Avittam', e: 'Dhanishta' },
    { t: 'Sadayam', e: 'Shatabhisha' },
    { t: 'Poorattathi', e: 'Purva Bhadrapada' },
    { t: 'Uthirattathi', e: 'Uttara Bhadrapada' },
    { t: 'Revathi', e: 'Revati' },
];

export const RAASI = [
    { t: 'Mesha', e: 'Aries' },
    { t: 'Vrushabha', e: 'Taurus' },
    { t: 'Midhuna', e: 'Gemini' },
    { t: 'Karkataka', e: 'Cancer' },
    { t: 'Simha', e: 'Leo' },
    { t: 'Kanya', e: 'Virgo' },
    { t: 'Tula', e: 'Libra' },
    { t: 'Vruchika', e: 'Scorpio' },
    { t: 'Dhanush', e: 'Sagittarius' },
    { t: 'Makara', e: 'Capricorn' },
    { t: 'Kumbha', e: 'Aquarius' },
    { t: 'Meen', e: 'Pisces' },
];
export const PROFILE_SECTION = [
    'basic',
    'personal',
    'work',
    'photo',
    'family',
    'horoscope',
];

export const CDN_URL = 'https://assets.marathimala.com';

export const PROFILE_STATUS = [
    {
        label: 'Unverified',
        value: 0,
        style: 'default',
    },
    {
        label: 'Verified',
        value: 10,
        style: 'info',
    },
    {
        label: 'Approved',
        value: 200,
        style: 'primary',
    },
    {
        label: 'Married',
        value: 300,
        style: 'success',
    },
    {
        label: 'Married-Other-Source',
        value: 301,
        style: 'info',
    },
    {
        label: 'Rejected',
        value: 500,
        style: 'warning',
    },
    {
        label: 'Duplicate',
        value: 501,
        style: 'danger',
    },
];

export const TAMILMALA_LANGUAGES = [
    'Tamil',
    'Telugu',
    'Saurashtra',
    'Christian',
];

export const TAMILMALA_CASTES = [
    {
        value: 'No caste bar',
        label: 'No caste bar',
        languages: TAMILMALA_LANGUAGES,
    },
    {
        value: 'Hindu Saurashtra',
        label: 'Hindu Saurashtra (சௌராட்டிரர்)',
        languages: ['Saurashtra'],
    },
    {
        value: 'Mudaliyar',
        label: 'Mudaliyar (முதலியார்)',
        languages: ['Tamil', 'Christian'],
    },
    {
        value: 'Vanniyar',
        label: 'Vanniyar',
        languages: ['Tamil', 'Christian'],
    },
    {
        value: 'Nadar',
        label: 'Nadar (நாடார்)',
        languages: ['Tamil', 'Christian'],
    },
    {
        value: 'Adhi dravidar',
        label: 'Adhi dravidar (ஆதி திராவிடர்)',
        languages: ['Tamil', 'Christian'],
    },
    {
        value: 'Naidu',
        label: 'Naidu (நாயுடு)',
        languages: ['Christian', 'Telugu'],
    },
    {
        value: 'Chettiyar',
        label: 'Chettiyar (செட்டியார்)',
        languages: ['Christian', 'Telugu'],
    },
    {
        value: 'Vannar',
        label: 'Vannar (வண்ணார்)',
        languages: ['Christian', 'Telugu'],
    },
    {
        value: 'Yadava',
        label: 'Yadava (யாதவர்)',
        languages: ['Christian', 'Telugu'],
    },
    { value: 'Reddy', label: 'Reddy (ரெட்டி)', languages: ['Telugu'] },
    {
        value: 'Emalavar / Oddar',
        label: 'Emalavar / Oddar (ஒட்டர்)',
        languages: ['Telugu'],
    },
];

export const TAMILMALA_SUBCASTES = {
    'No caste bar': [
        {
            value: 'No caste bar',
            label: 'No caste bar',
        },
    ],
    'Hindu Saurashtra': [
        {
            value: 'Hindu Saurashtra',
            label: 'Hindu Saurashtra (சௌராட்டிரர்)',
        },
    ],
    Mudaliyar: [
        {
            value: 'Sengunthar / Kaikolar',
            label: 'Sengunthar / Kaikolar (செங்குந்தர் / கைக்கோளர்)',
        },
        {
            value: 'Agamudayar / Arcot / Thuluva',
            label:
                'Agamudayar / Arcot / Thuluva (அகமுடையார் / ஆற்காடு / துளுவ)',
        },
    ],
    Vanniyar: [
        {
            value: 'Vanniya kula kshatriyar',
            label: 'Vanniya kula kshatriyar (வன்னியர் குல சத்ரியர்)',
        },
        {
            value: 'Gounder',
            label: 'Gounder (கவுண்டர்)',
        },
        {
            value: 'Naicker',
            label: 'Naicker (நாயக்கர்)',
        },
        {
            value: 'Arasu',
            label: 'Arasu (அரசு)',
        },
        {
            value: 'Padayachi',
            label: 'Padayachi (படையாட்சி)',
        },
        {
            value: 'Palli',
            label: 'Palli (பள்ளி)',
        },
        {
            value: 'Pandal',
            label: 'Pandal (பந்தல்)',
        },
        {
            value: 'Vannia Reddiar',
            label: 'Vannia Reddiar (வன்னிய ரெட்டியார்)',
        },
        {
            value: 'Vanniyar',
            label: 'Vanniyar (வன்னியர்)',
        },
    ],
    Maruthuvar: [],
    Chettiyar: [
        {
            value: 'Devanga chettiyar',
            label: 'Devanga chettiyar (தேவாங்க செட்டியார்)',
        },
        {
            value: 'Vanniya chettiyar',
            label: 'Vanniya chettiyar (வாணியச் செட்டியார்)',
        },
        {
            value: 'Saiva chettiyar',
            label: 'Saiva chettiyar (சைவ செட்டியார்)',
        },
        {
            value: 'Beri chettiyar',
            label: 'Beri chettiyar (பெரி செட்டியார்)',
        },
        {
            value: 'Arya vysya',
            label: 'Arya vysya',
        },
        {
            value: 'Komutti',
            label: 'Komutti (கோமுட்டி)',
        },
        {
            value: 'Nattukottai chettiyar',
            label: 'Nattukottai chettiyar (நாட்டுக்கோட்டை செட்டியார்)',
        },
        {
            value: '24 manai telugu chettiyar ',
            label: '24 manai telugu chettiyar (24 மனை தெலுங்குச் செட்டியார்)',
        },
        {
            value: 'Sozhiya chettiyar',
            label: 'Sozhiya chettiyar (சோழிய செட்டியார்)',
        },
        {
            value: '24 manai telugu chettiyar 16 veedu',
            label:
                '24 manai telugu chettiyar 16 veedu (24 மனை தெலுங்குச்செட்டியார்)',
        },
        {
            value: '24 manai telugu chettiyar 8 veedu',
            label:
                '24 manai telugu chettiyar 8 veedu (24 மனை தெலுங்குச்செட்டியார்)',
        },
        {
            value: 'Achirapakkam chettiyar',
            label: 'Achirapakkam chettiyar',
        },
        {
            value: 'Agaram vellan chettiyar',
            label: 'Agaram vellan chettiyar (அகரம் வெள்ளாஞ் செட்டியார்)',
        },
        {
            value: 'Elur',
            label: 'Elur',
        },
        {
            value: 'Gandla / Ganiga',
            label: 'Gandla / Ganiga',
        },
        {
            value: 'Kasukara',
            label: 'Kasukara',
        },
        {
            value: 'Kongu chettiyar',
            label: 'Kongu chettiyar (கொங்கு செட்டியார்)',
        },
        {
            value: 'Kuruhini chettiyar',
            label: 'Kuruhini chettiyar',
        },
        {
            value: 'Manjapudur chettiyar',
            label: 'Manjapudur chettiyar',
        },
        {
            value: 'Padma saliar',
            label: 'Padma saliar',
        },
        {
            value: 'Pannirandam chettiyar',
            label: 'Pannirandam chettiyar (பன்னிரண்டாம் செட்டியார்)',
        },
        {
            value: 'Parvatha rajakulam',
            label: 'Parvatha rajakulam (பர்வதராஜகுலம்)',
        },
        {
            value: 'Pattinavar',
            label: 'Pattinavar',
        },
        {
            value: 'Pattusali',
            label: 'Pattusali',
        },
        {
            value: 'Sadhu chettiyar',
            label: 'Sadhu chettiyar',
        },
        {
            value: 'Senai thalaivar',
            label: 'Senai thalaivar (சேனைத்தலைவர்)',
        },
        {
            value: 'Telugupatti',
            label: 'Telugupatti',
        },
        {
            value: 'Vadambar',
            label: 'Vadambar',
        },
        {
            value: 'Vellan chettiyar',
            label: 'Vellan chettiyar (வெள்ளாஞ் செட்டியார்)',
        },
    ],
    Nadar: [
        {
            value: 'Gramani / Sanar',
            label: 'Gramani / Sanar (கிராமணி / சாணார்)',
        },
        {
            value: 'Hindu nadar',
            label: 'Hindu nadar (இந்து நாடார்)',
        },
        {
            value: 'Christian nadar',
            label: 'Christian nadar (கிறிஸ்துவ நாடார்)',
        },
        {
            value: 'Vettaikkaara nadar',
            label: 'Vettaikkaara nadar (வேட்டைக்கார நாடார்)',
        },
        {
            value: 'Kongu nadar',
            label: 'Kongu nadar (கொங்கு நாடார்)',
        },
    ],
    'Kulalar / Udayar': [],
    Karuneegar: [
        {
            value: 'Seer / Saradai',
            label: 'Seer / Saradai (சீர் / சரட்டு)',
        },
    ],
    'Jangam / Thambiraar': [],
    Vishwakarma: [
        {
            value: 'Black smith',
            label: 'Black smith (கருமார்)',
        },
        {
            value: 'Carpentry / Vadrangi / Vadla',
            label: 'Carpentry / Vadrangi / Vadla (தச்சர்)',
        },
        {
            value: 'Goldsmiths',
            label: 'Goldsmiths (தட்டார்)',
        },
        {
            value: 'Sculptor / Shilpi',
            label: 'Sculptor / Shilpi (சிற்பி)',
        },
    ],
    Pillai: [
        {
            value: 'Veerakkudi vellalar',
            label: 'Veerakkudi vellalar (வீரக்குடி வெள்ளாளர்)',
        },
        {
            value: 'Sengunthar / Kaikolar',
            label: 'Sengunthar / Kaikolar (செங்குந்தர் / கைக்கோளர்)',
        },
        {
            value: 'Thondai mandala vellala',
            label: 'Thondai mandala vellala (தொண்டை மண்டல வேளாளர்)',
        },
        {
            value: 'Isai vellalar',
            label: 'Isai vellalar (இசை வேளாளர்)',
        },
        {
            value: 'Nanjil mudali',
            label: 'Nanjil mudali (நாஞ்சில் முதலி)',
        },
        {
            value: 'Nanjil pillai',
            label: 'Nanjil pillai (நாஞ்சில் பிள்ளை)',
        },
        {
            value: 'Saiva pillai tirunelveli',
            label: 'Saiva pillai tirunelveli (சைவ பிள்ளை திருநெல்வேலி)',
        },
        {
            value: 'Saiva pillai thanjavur',
            label: 'Saiva pillai thanjavur (சைவ பிள்ளை தஞ்சாவூர்)',
        },
        {
            value: 'Sozhiya vellalar',
            label: 'Sozhiya vellalar (சோழிய வெள்ளாளர்)',
        },
        {
            value: 'Aarunattu vellalar',
            label: 'Aarunattu vellalar (ஆறுநாட்டு வெள்ளாளர்)',
        },
        {
            value: 'Agamudayar / Arcot / Thuluva',
            label:
                'Agamudayar / Arcot / Thuluva (அகமுடையார் / ஆற்காடு / துளுவ)',
        },
        {
            value: 'Cherakula vellalar',
            label: 'Cherakula vellalar (சேர வேளாளர்)',
        },
        {
            value: 'Desikar',
            label: 'Desikar (தேசிகர்)',
        },
        {
            value: 'Desikar thanjavur',
            label: 'Desikar thanjavur (தேசிகர் தஞ்சாவூர்)',
        },
        {
            value: 'Illaththu pillai',
            label: 'Illaththu pillai (இல்லத்துப் பிள்ளை)',
        },
        {
            value: 'Karkathar',
            label: 'Karkathar (கார்காத்தார்)',
        },
        {
            value: 'Kodikal pillai',
            label: 'Kodikal pillai (கொடிக்கால் பிள்ளை)',
        },
        {
            value: 'Nankudi vellalar',
            label: 'Nankudi vellalar (நன்குடி வேளாளர்)',
        },
        {
            value: 'Othuvaar',
            label: 'Othuvaar (ஓதுவார்)',
        },
        {
            value: 'Pandiya vellalar',
            label: 'Pandiya vellalar (பாண்டிய வேளாளர்)',
        },
    ],
    Devar: [
        {
            value: 'Maravar',
            label: 'Maravar (மறவர்)',
        },
        {
            value: 'Kallar',
            label: 'Kallar (கள்ளர்)',
        },
        {
            value: 'Agmudaiyar',
            label: 'Agmudaiyar (அகமுடையார்)',
        },
        {
            value: 'Ambalakarar',
            label: 'Ambalakarar (அம்பலகாரர்)',
        },
        {
            value: 'Appanad kondayamkottai maravar',
            label:
                'Appanad kondayamkottai maravar (ஆப்பனாடு கொண்டையங்கோட்டை மறவர்)',
        },
        {
            value: 'Easanattu kallar',
            label: 'Easanattu kallar (ஈசநாட்டு கள்ளர்)',
        },
        {
            value: 'Maniyakarar',
            label: 'Maniyakarar (மணியக்காரர்)',
        },
        {
            value: 'Piramalai kallar',
            label: 'Piramalai kallar (பிரமலை கள்ளர்)',
        },
        {
            value: 'Rajakula agmudaiyar',
            label: 'Rajakula agmudaiyar (ராஜகுல அகமுடையார்)',
        },
        {
            value: 'Sembanad maravar',
            label: 'Sembanad maravar',
        },
        {
            value: 'Servai',
            label: 'Servai (சேர்வை)',
        },
        {
            value: 'Thanjavur kallar',
            label: 'Thanjavur kallar (தஞ்சாவூர் கள்ளர்)',
        },
        {
            value: 'Vallambar',
            label: 'Vallambar (வல்லம்பர்)',
        },
    ],
    'Bhramin / Iyer': [
        {
            value: 'Vadama',
            label: 'Vadama',
        },
        {
            value: 'Vathima',
            label: 'Vathima',
        },
        {
            value: 'Ashtasahasram',
            label: 'Ashtasahasram',
        },
        {
            value: 'Brahacharanam',
            label: 'Brahacharanam',
        },
    ],
    'Adhi dravidar': [
        {
            value: 'Adhi dravidar',
            label: 'Adhi dravidar (ஆதி திராவிடர்)',
        },
    ],
    Vannar: [],
    Yadava: [
        {
            value: 'Tamil yadavar',
            label: 'tamil yadavar (தமிழ் யாதவர்)',
        },
        {
            value: 'Telugu yadav',
            label: 'Telugu yadav',
        },
        {
            value: 'Doddi',
            label: 'Doddi',
        },
        {
            value: 'Erragolla',
            label: 'Erragolla',
        },
        {
            value: 'Karna',
            label: 'Karna',
        },
        {
            value: 'Kuruma',
            label: 'Kuruma',
        },
        {
            value: 'Pakanati',
            label: 'Pakanati',
        },
        {
            value: 'Puja',
            label: 'Puja',
        },
        {
            value: 'Yadava golla',
            label: 'Yadava golla',
        },
        {
            value: 'Ala golla',
            label: 'Ala golla',
        },
        {
            value: 'Aheer / Ahir',
            label: 'Aheer / Ahir',
        },
        {
            value: 'Dadhor',
            label: 'Dadhor',
        },
        {
            value: 'Das',
            label: 'Das',
        },
        {
            value: 'Gadri / Gadariya',
            label: 'Gadri / Gadariya',
        },
        {
            value: 'Gawli',
            label: 'Gawli',
        },
        {
            value: 'Gwala',
            label: 'Gwala',
        },
        {
            value: 'Goala / Gola / Golla',
            label: 'Goala / Gola / Golla',
        },
        {
            value: 'Gop / Gopal / Gopala',
            label: 'Gop / Gopal / Gopala',
        },
        {
            value: 'Goriya',
            label: 'Goriya',
        },
        {
            value: 'Gouda',
            label: 'Gouda',
        },
        {
            value: 'Gwalvanshi',
            label: 'Gwalvanshi',
        },
        {
            value: 'Jadav',
            label: 'Jadav',
        },
        {
            value: 'Konar',
            label: 'Konar (கோனார்)',
        },
        {
            value: 'Krishnauth',
            label: 'Krishnauth (கிருஷ்ணாவும்)',
        },
        {
            value: 'Kurubas / Gollas',
            label: 'Kurubas / Gollas',
        },
        {
            value: 'Mandal',
            label: 'Mandal',
        },
        {
            value: 'Manjrauth',
            label: 'Manjrauth',
        },
        {
            value: 'Nandavanshi',
            label: 'Nandavanshi',
        },
        {
            value: 'Raut',
            label: 'Raut',
        },
        {
            value: 'Suryavanshi',
            label: 'Suryavanshi',
        },
        {
            value: 'Thethwar',
            label: 'Thethwar',
        },
        {
            value: 'Yadav',
            label: 'Yadav',
        },
        {
            value: 'Yaduvanshi',
            label: 'Yaduvanshi',
        },
    ],
    Arunthathiyar: [],
    'Bhramin / Iyengar': [
        {
            value: 'Thenkalai',
            label: 'Thenkalai (தென்கலை)',
        },
        {
            value: 'Vadakalai',
            label: 'Vadakalai (வடகலை)',
        },
    ],
    'Veerakkudi vellaalar': [],
    'Devendra kula vellalar': [
        {
            value: 'Pallar',
            label: 'Pallar (பள்ளர்)',
        },
    ],
    'Paruvatha rajakulam': [],
    'Kongu vellala gounder': [
        {
            label: 'Aadai',
            value: 'Aadai',
        },
        {
            label: 'Aadhi',
            value: 'Aadhi',
        },
        {
            label: 'Aanthai',
            value: 'Aanthai',
        },
        {
            label: 'Aavan',
            value: 'Aavan',
        },
        {
            label: 'Alagan',
            value: 'Alagan',
        },
        {
            label: 'Andai',
            value: 'Andai',
        },
        {
            label: 'Anudhuvan',
            value: 'Anudhuvan',
        },
        {
            label: 'Cheran',
            value: 'Cheran',
        },
        {
            label: 'Devendran',
            value: 'Devendran',
        },
        {
            label: 'Eenjan',
            value: 'Eenjan',
        },
        {
            label: 'Ennai',
            value: 'Ennai',
        },
        {
            label: 'Kaadai',
            value: 'Kaadai',
        },
        {
            label: 'Kaari',
            value: 'Kaari',
        },
        {
            label: 'Kanakkan',
            value: 'Kanakkan',
        },
        {
            label: 'Kannan',
            value: 'Kannan',
        },
        {
            label: 'Kannandhai',
            value: 'Kannandhai',
        },
        {
            label: 'Keeran',
            value: 'Keeran',
        },
        {
            label: 'Koorai',
            value: 'Koorai',
        },
        {
            label: 'Koventhar',
            value: 'Koventhar',
        },
        {
            label: 'Kuzhlaayan',
            value: 'Kuzhlaayan',
        },
        {
            label: 'Maadai',
            value: 'Maadai',
        },
        {
            label: 'Maniyan',
            value: 'Maniyan',
        },
        {
            label: 'Medhi',
            value: 'Medhi',
        },
        {
            label: 'Muthan',
            value: 'Muthan',
        },
        {
            label: 'Muzhlukkadhan',
            value: 'Muzhlukkadhan',
        },
        {
            label: 'Nattu gounder',
            value: 'Nattu gounder',
        },
        {
            label: 'Odhaalar',
            value: 'Odhaalar',
        },
        {
            label: 'Paandian',
            value: 'Paandian',
        },
        {
            label: 'Padaithalaiyan',
            value: 'Padaithalaiyan',
        },
        {
            label: 'Panaiyan',
            value: 'Panaiyan',
        },
        {
            label: 'Panangadai',
            value: 'Panangadai',
        },
        {
            label: 'Pannai',
            value: 'Pannai',
        },
        {
            label: 'Pannan',
            value: 'Pannan',
        },
        {
            label: 'Pavalan',
            value: 'Pavalan',
        },
        {
            label: 'Payiran',
            value: 'Payiran',
        },
        {
            label: 'Periyan',
            value: 'Periyan',
        },
        {
            label: 'Perunkudi',
            value: 'Perunkudi',
        },
        {
            label: 'Pillan',
            value: 'Pillan',
        },
        {
            label: 'Podiyan',
            value: 'Podiyan',
        },
        {
            label: 'Ponnan',
            value: 'Ponnan',
        },
        {
            label: 'Poochadhai poodhiyan',
            value: 'Poochadhai poodhiyan',
        },
        {
            label: 'Poosan',
            value: 'Poosan',
        },
        {
            label: 'Perizhanthan',
            value: 'Perizhanthan',
        },
        {
            label: 'Sathandhai',
            value: 'Sathandhai',
        },
        {
            label: 'Sedan',
            value: 'Sedan',
        },
        {
            label: 'Sellan',
            value: 'Sellan',
        },
        {
            label: 'Sempoothan',
            value: 'Sempoothan',
        },
        {
            label: 'Sengannan',
            value: 'Sengannan',
        },
        {
            label: 'Sengunni',
            value: 'Sengunni',
        },
        {
            label: 'Seralan',
            value: 'Seralan',
        },
        {
            label: 'Sevadi',
            value: 'Sevadi',
        },
        {
            label: 'Thodai',
            value: 'Thodai',
        },
        {
            label: 'Thooran',
            value: 'Thooran',
        },
        {
            label: 'Vannakkan',
            value: 'Vannakkan',
        },
        {
            label: 'Veliyan',
            value: 'Veliyan',
        },
        {
            label: 'Vellamban',
            value: 'Vellamban',
        },
        {
            label: 'Venduvan',
            value: 'Venduvan',
        },
        {
            label: 'Viliyan',
            value: 'Viliyan',
        },
        {
            label: 'Villi',
            value: 'Villi',
        },
    ],
    'Kurumba goundar': [
        {
            value: 'Paalkar',
            label: 'Paalkar',
        },
    ],
    'Parkava kulam / Udaiyar': [
        {
            value: 'Nathaman udaiyar',
            label: 'Nathaman udaiyar (நத்தமான் உடையார்)',
        },
        {
            value: 'Moopanar',
            label: 'Moopanar (மூப்பனார்)',
        },
        {
            value: 'Suruthiman',
            label: 'Suruthiman (சுருதிமான்)',
        },
        {
            value: 'Nainar',
            label: 'Nainar (நயினார்)',
        },
        {
            value: 'Malayaman',
            label: 'Malayaman (மலையமான்)',
        },
    ],
    Valluvar: [],
    Naidu: [
        {
            value: 'Kamma',
            label: 'Kamma (கம்மவார்)',
        },
        {
            value: 'Balija',
            label: 'Balija (பலிஜா)',
        },
        {
            value: 'Gavara',
            label: 'Gavara (கவரா)',
        },
        {
            value: 'Vadugan',
            label: 'Vadugan',
        },
        {
            value: 'Muthuraja',
            label: 'Muthuraja (முத்துராஜா)',
        },
        {
            value: 'Raju',
            label: 'Raju',
        },
        {
            value: 'Padmavelamu naidu',
            label: 'Padmavelamu naidu',
        },
        {
            value: 'Ediga / Goud',
            label: 'Ediga / Goud',
        },
        {
            value: 'Gajula',
            label: 'Gajula',
        },
        {
            value: 'Kapu',
            label: 'Kapu',
        },
        {
            value: 'Munnuru kapu',
            label: 'Munnuru kapu',
        },
        {
            value: 'Naidu',
            label: 'Naidu (நாயுடு)',
        },
        {
            value: 'Perika',
            label: 'Perika',
        },
        {
            value: 'Raja kambalathu naicker',
            label: 'Raja kambalathu naicker (ராஜ கம்பளத்து நாயக்கர்)',
        },
        {
            value: 'Reddy',
            label: 'Reddy (ரெட்டி)',
        },
        {
            label: 'Shetty balija',
            value: 'Shetty balija',
        },
        {
            label: 'Surya balija',
            value: 'Surya balija',
        },
        {
            label: 'Telaga',
            value: 'Telaga',
        },
        {
            label: 'Turupu kapu',
            value: 'Turupu kapu',
        },
        {
            label: 'Vada balija',
            value: 'Vada balija',
        },
        {
            label: 'Velama (வெலமா)',
            value: 'Velama',
        },
        {
            label: 'Yadava',
            value: 'Yadava',
        },
    ],
    Reddy: [],
    'Emalavar / Oddar': [],
};

export const paymentLinks = [
    {
        link:
            'https://tamilmala.stores.instamojo.com/product/2936618/tamilmala-silver-package/',
        text: 'Rs. 599',
    },
    {
        link:
            'https://tamilmala.stores.instamojo.com/product/2936617/tamilmala-gold-package/',
        text: 'Rs. 1500',
    },
    {
        link:
            'https://tamilmala.stores.instamojo.com/product/2936620/tamilmala-diamond-package-1c9fc',
        text: 'Rs. 2700',
    },
];
