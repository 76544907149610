import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../utils/ReduxFormUtils';

export const validate = (values) => {
    const errors = {};
    if (!values.oldPassword) {
        errors.oldPassword = 'Required';
    } else if (!values.password) {
        errors.password = 'Required';
    } else if (!values.confirmPassword) {
        errors.confirmPassword = 'Required';
    } else if (values.password !== values.confirmPassword) {
        errors.password = "Password and its confirmation doesn't match";
    }
    return errors;
};

class ChangePassword extends PureComponent {
    render() {
        const { handleSubmit, submitting } = this.props;

        return (
            <div>
                <div className="container main heading text-center">
                    <h1>Change password</h1>
                    <p>If you like to update your password?</p>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-6 col-xs-12 col-md-offset-4 col-sm-offset-3">
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <Field
                                    name="oldPassword"
                                    type="password"
                                    className="form-control"
                                    component={renderField}
                                    label="Old Password"
                                    placeholder="Your old password"
                                />
                                <Field
                                    name="password"
                                    type="password"
                                    className="form-control"
                                    component={renderField}
                                    label="New Password"
                                    placeholder="Your new password"
                                />
                                <Field
                                    name="confirmPassword"
                                    type="password"
                                    className="form-control"
                                    component={renderField}
                                    label="Confirm New Password"
                                    placeholder="Confirm new password"
                                />
                                <div className="form-group">
                                    <button type="submit" className="btn btn-lg btn-wide btn-success" disabled={submitting}>
                                        {submitting ? 'Updating...' : 'Change'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// Decorate the form component
ChangePassword = reduxForm({
    form: 'changePassword',
    validate,
})(ChangePassword);

export default ChangePassword;
