import React, { Component } from 'react';
import { connect } from 'react-redux';
import Glider from 'react-glider';
import 'glider-js/glider.min.css';

import { push } from '../utils/history';

import NavContainer from '../containers/NavContainer';
import Footer from './Footer';

import { CDN_URL } from '../constants/Constants';
import WhatsAppButton from './WhatsAppButton';

class Home extends Component {
    register = (e) => {
        e.preventDefault();
        push('/signup');
    };

    signIn = (e) => {
        e.preventDefault();
        push('/login');
    };

    myProfile = (e) => {
        e.preventDefault();
        push('/profile/edit/basic');
    };

    searchProfile = (e) => {
        e.preventDefault();
        push('/profile/search');
    };

    render() {
        const FEATURED_BRIDE = [
            {
                mid: 'TF8274',
                photo_url:
                    '/profile-photo/small/6057725d3a26216a5258c40f-GO91ASA1F8.jpg',
            },
            {
                mid: 'TF8285',
                photo_url:
                    '/profile-photo/small/605772af3a26216a5258c429-Nfx6ddLsKb.jpg',
            },
            {
                mid: 'TF6424',
                photo_url:
                    '/profile-photo/small/601962d299cbf51fa4b03174-rOQG7ABJ3j.jpg',
            },
            {
                mid: 'TF6355',
                photo_url:
                    '/profile-photo/small/6016718e015a36e9b3129573-tmvKRXHMmF.jpg',
            },
            {
                mid: 'TF6498',
                photo_url:
                    '/profile-photo/small/602b435c11f7bdbb4bff51d1-lNxeCZGRBP.jpg',
            },
            {
                mid: 'TF8247',
                photo_url:
                    '/profile-photo/small/605771b83a26216a5258c3cf-6M39Yo1IFD.jpg',
            },
        ];
        const FEATURED_GROOM = [
            {
                mid: 'TM6674',
                photo_url:
                    '/profile-photo/small/60479c723a637ba14f1ceebe-V2W4liQRKM.jpg',
            },
            {
                mid: 'TM8377',
                photo_url:
                    '/profile-photo/small/6069a1c85fe6dfbb0fb442cc-l7S34kzjyf.jpg',
            },
            {
                mid: 'TM6448',
                photo_url:
                    '/profile-photo/small/60212c900c984a8a087e57ca-c2vvqFbEZs.jpg',
            },
            {
                mid: 'TM6249',
                photo_url:
                    '/profile-photo/small/6006e16cb1e84b3120721041-wXugWmW5Um.jpg',
            },
            {
                mid: 'TM6081',
                photo_url:
                    '/profile-photo/small/5fe454de44c3ec9811134844-b8tzm0O000.jpg',
            },
            {
                mid: 'TM6062',
                photo_url:
                    '/profile-photo/small/5fe0e58c0e695f1fd594cf8e-QE30nsEGzF.jpg',
            },
        ];
        const HAPPY_MARRIAGES = [
            {
                caption: 'Sathya Narayana Rao & Pavithra Bai',
                photo_url:
                    'http://assets.marathimala.com/assets/happy-marriages/Sathya Narayana Rao & Pavithra Bai.jpg',
            },
            {
                caption: 'Sathish Kumar & Pavithra Bai',
                photo_url:
                    'http://assets.marathimala.com/assets/happy-marriages/Sathish Kumar & Pavithra Bai.jpg',
            },
            {
                caption: 'Latha & Harish Rao',
                photo_url:
                    'http://assets.marathimala.com/assets/happy-marriages/Latha & harish rao.jpg',
            },
            {
                caption: 'Deepak & Griheetha',
                photo_url:
                    'http://assets.marathimala.com/assets/happy-marriages/Deepak & Griheetha.jpg',
            },
            {
                caption: 'Rajesh Rao & Shanthini',
                photo_url:
                    'http://assets.marathimala.com/assets/happy-marriages/Rajesh-Rao-Shanthini.jpeg',
            },
            {
                caption: 'Raghavendra Rao & Likhitha Telkar',
                photo_url:
                    'http://assets.marathimala.com/assets/happy-marriages/Raghavendra-Rao-Likhitha-Telkar.jpeg',
            },
            {
                caption: 'Hema Ajay & Ranasubhe',
                photo_url:
                    'http://assets.marathimala.com/assets/happy-marriages/Hema-Ajay-Ranasubhe.jpeg',
            },
            {
                caption: 'Manoj & Arthi',
                photo_url:
                    'http://assets.marathimala.com/assets/happy-marriages/Manoj-Arthi.jpeg',
            },
            {
                caption: 'Sandeep & his better-half',
                photo_url:
                    'http://assets.marathimala.com/assets/happy-marriages/Sandeep.jpeg',
            },
            {
                caption: 'Anand Rao & Priyanka',
                photo_url:
                    'http://assets.marathimala.com/assets/happy-marriages/Anand-Rao-Priyanka.jpeg',
            },
            {
                caption: 'Sunil Avanthkar & Ashritha',
                photo_url:
                    'http://assets.marathimala.com/assets/happy-marriages/SunilAvanthkar-Ashritha.jpeg',
            },
            {
                caption: 'Arun Manohar & Mridula',
                photo_url:
                    'http://assets.marathimala.com/assets/happy-marriages/ArunManohar-Mridula.jpeg',
            },
            {
                caption: 'Shreekanth & Rakshitha',
                photo_url:
                    'http://assets.marathimala.com/assets/happy-marriages/Shreekanth-Rakshitha.jpeg',
            },
            {
                caption: 'Kaladhar',
                photo_url:
                    'http://assets.marathimala.com/assets/happy-marriages/Kaladhar.jpeg',
            },
            {
                caption: 'Kiran Mahendraker',
                photo_url:
                    'http://assets.marathimala.com/assets/happy-marriages/Kiran-Mahendraker.jpeg',
            },
            {
                caption: 'Yashwanth & Arthi Priya',
                photo_url:
                    'http://assets.marathimala.com/assets/happy-marriages/Yashwanth-ArthiPriya.jpeg',
            },
            {
                caption: 'Mithra & Sunil Kumar',
                photo_url:
                    'http://assets.marathimala.com/assets/happy-marriages/Mithra-SunilKumar.jpeg',
            },
            {
                caption: 'Yashwanth & Swapna',
                photo_url:
                    'http://assets.marathimala.com/assets/happy-marriages/Yashwanth-Swapna.jpeg',
            },
            {
                caption: 'RajVanthi & Hariharan',
                photo_url:
                    'http://assets.marathimala.com/assets/happy-marriages/RajVanthi-Hariharan.jpg',
            },
            {
                caption: 'Nivetha & JainthKumar',
                photo_url:
                    'http://assets.marathimala.com/assets/happy-marriages/Nivetha-JainthKumar.jpg',
            },
            {
                caption: 'Manjula & SaranPradeep',
                photo_url:
                    'http://assets.marathimala.com/assets/happy-marriages/Manjula-SaranPradeep.jpg',
            },
            {
                caption: 'Nadhini & Kiran',
                photo_url:
                    'http://assets.marathimala.com/assets/happy-marriages/Nadhini-Kiran.jpg',
            },
            {
                caption: 'Sunitha & Karthik',
                photo_url:
                    'http://assets.marathimala.com/assets/happy-marriages/Karthik-Sunitha.jpg',
            },
            {
                caption: 'Vidhya & GokulRao',
                photo_url:
                    'http://assets.marathimala.com/assets/happy-marriages/GokulRao-Vidhya.jpg',
            },
            {
                caption: 'Surendranath & Kavitha',
                photo_url:
                    'http://assets.marathimala.com/assets/happy-marriages/Surendranath-Kavitha.jpg',
            },
        ];

        const { isLoggedIn, isMobile } = this.props;
        return (
            <div style={{ textAlign: 'center' }}>
                <NavContainer from="home" />
                <div
                    className="jumbotron"
                    style={{ paddingLeft: 20, paddingRight: 20 }}
                >
                    <div className="overlay" />
                    <div className="jumbotron-content container-fluid">
                        <h2>Tamilmala matrimony</h2>
                        <h2 style={{ marginBottom: '4rem' }}>
                            Exclusive for Tamil Community.
                        </h2>
                        {isLoggedIn ? (
                            <p>
                                <a
                                    className="btn btn-primary btn-lg"
                                    style={{ marginRight: 10 }}
                                    href="/"
                                    onClick={this.myProfile}
                                    role="button"
                                >
                                    My Profile
                                </a>
                                <a
                                    className="btn btn-info btn-lg"
                                    href="/"
                                    onClick={this.searchProfile}
                                    role="button"
                                >
                                    Search Profile
                                </a>
                            </p>
                        ) : (
                            <p>
                                <a
                                    className="btn cta-btn btn-lg"
                                    style={{ marginRight: 10 }}
                                    href="/"
                                    onClick={this.register}
                                    role="button"
                                >
                                    Register now!
                                </a>
                                <a
                                    className="btn cta-btn btn-lg"
                                    href="/"
                                    onClick={this.signIn}
                                    role="button"
                                >
                                    Login
                                </a>
                            </p>
                        )}
                    </div>
                </div>
                <div className="container">
                    <div
                        className="row"
                        style={{
                            marginBottom: 40,
                            paddingLeft: 10,
                            paddingRight: 10,
                        }}
                    >
                        <h2 className="section-header">
                            <span className="highlight-color">Featured</span>{' '}
                            Bride
                        </h2>
                        {FEATURED_BRIDE.map((fb, i) => (
                            <div key={fb.mid} className="col-xs-6 col-md-2">
                                <a href="/" className="thumbnail">
                                    <div className="thumb-image">
                                        <img
                                            style={{ display: 'block' }}
                                            src={`${CDN_URL}${fb.photo_url}`}
                                            alt={fb.mid}
                                        />
                                    </div>
                                    <h6>{fb.mid}</h6>
                                </a>
                            </div>
                        ))}
                    </div>
                    <div
                        className="row"
                        style={{
                            marginBottom: 40,
                            paddingLeft: 10,
                            paddingRight: 10,
                        }}
                    >
                        <h2 className="section-header">
                            <span className="highlight-color">Featured</span>{' '}
                            Groom
                        </h2>
                        {FEATURED_GROOM.map((fg, i) => (
                            <div key={fg.mid} className="col-xs-6 col-md-2">
                                <a href="/" className="thumbnail">
                                    <div className="thumb-image">
                                        <img
                                            style={{ display: 'block' }}
                                            src={`${CDN_URL}${fg.photo_url}`}
                                            alt={fg.mid}
                                        />
                                    </div>
                                    <h6>{fg.mid}</h6>
                                </a>
                            </div>
                        ))}
                    </div>
                    {/* <div
                        className="row"
                        style={{
                            marginBottom: 40,
                            paddingLeft: 10,
                            paddingRight: 10,
                        }}
                    >
                        <p
                            className="clearfix"
                            style={{
                                backgroundColor: '#f6f7f9',
                                textAlign: 'center',
                                border: 'none',
                                boxShadow: 'none',
                            }}
                        >
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.facebook.com/devale.ajay.9"
                            >
                                <img
                                    style={{ maxWidth: '100%' }}
                                    alt="Ajay Kumar fotography promo"
                                    src="https://marathimala.s3.amazonaws.com/assets/promo/ajay-kumar-fotography.jpg"
                                />
                            </a>
                        </p>
                    </div> */}
                    <div
                        className="row"
                        style={{
                            marginBottom: 40,
                            paddingLeft: 10,
                            paddingRight: 10,
                        }}
                    >
                        <h2 className="section-header">
                            <span className="highlight-color">Happy</span>{' '}
                            Marriages
                        </h2>
                        <Glider
                            slidesToShow="auto"
                            slidesToScroll="auto"
                            duration={0.25}
                            responsive={[
                                {
                                    breakpoint: 775,
                                    settings: {
                                        slidesToShow: '3',
                                        slidesToScroll: '2',
                                    },
                                },
                            ]}
                            hasDots
                            hasArrows
                            draggable
                        >
                            {HAPPY_MARRIAGES.map((hm, i) => (
                                <div key={i}>
                                    <a href="/signup" className="thumbnail">
                                        <div className="thumb-image">
                                            <img
                                                style={{ display: 'block' }}
                                                src={`${hm.photo_url}`}
                                                alt={hm.caption}
                                            />
                                        </div>
                                        <h6>{hm.caption}</h6>
                                    </a>
                                </div>
                            ))}
                        </Glider>
                    </div>
                    <div className="row" style={{ marginBottom: 40 }}>
                        <WhatsAppButton />
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default connect((state) => ({
    isLoggedIn: state.authed.accessToken,
    isMobile: state.environment.isMobile,
}))(Home);
