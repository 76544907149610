import React, { useState } from 'react';
import { PROFILE_STATUS } from '../constants/Constants';
import Checkbox from '../utils/Checkbox';

const convertDate = (inputDate) => {
    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const d = new Date(inputDate);
    return [d.getDate(), monthNames[d.getMonth()], d.getFullYear()].join(' ');
};

const AdminProfileList = ({ data, updateStatus, updateFeatured }) => (
    <table className="table table-hover table-condensed">
        <thead>
            <tr>
                <th>#</th>
                <th>Name</th>
                <th>Surname</th>
                <th>Gender</th>
                <th>DOB</th>
                <th>City</th>
                <th>Status</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            {data &&
                data.map((p) => (
                    <AdminProfile
                        key={p.mid}
                        profile={p}
                        onClickHandler={updateStatus}
                        onFeaturedHandler={updateFeatured}
                    />
                ))}
        </tbody>
    </table>
);

const AdminProfile = ({ profile, onClickHandler, onFeaturedHandler }) => {
    const [displayAction, setDisplayAction] = useState(false);
    return (
        <tr key={profile.mid}>
            <th scope="row">{profile.mid}</th>
            <td>{profile.name}</td>
            <td>{profile.surname}</td>
            <td>{profile.gender}</td>
            <td>{convertDate(profile.dob)}</td>
            <td>{profile.city}</td>
            <td>
                <div className="btn-group" role="group">
                    <button
                        type="button"
                        className={`btn btn-${
                            PROFILE_STATUS.find(
                                (s) => s.value === profile.status,
                            ).style
                        } dropdown-toggle`}
                        onClick={(e) => {
                            e.preventDefault();
                            setDisplayAction(!displayAction);
                        }}
                    >
                        {profile.status_label}
                        <span className="caret" />
                    </button>
                    <ul
                        className="dropdown-menu"
                        style={{ display: displayAction ? 'block' : 'none' }}
                    >
                        {PROFILE_STATUS.map((s) => (
                            <li key={s.value}>
                                <a
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onClickHandler(
                                            profile.profile_id,
                                            s.value,
                                        );
                                        setDisplayAction(false);
                                    }}
                                >
                                    {s.label}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </td>
            <td>
                <Checkbox
                    value={profile.profile_id}
                    label="Featured"
                    name="featured"
                    isGroup={false}
                    isChecked={profile.is_featured}
                    disabled={profile.status_label !== 'Approved'}
                    handleCheckboxChange={(_name, _value, isChecked) => {
                        if (profile.is_featured === isChecked) {
                            return;
                        }
                        onFeaturedHandler(profile.profile_id, isChecked);
                    }}
                />
            </td>
        </tr>
    );
};

export default AdminProfileList;
