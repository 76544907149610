import React from 'react';

import { connect } from 'react-redux';

import {
    viewProfile, downloadProfile, addShortlist, removeShortlist,
} from '../actions/ProfileActions';
import Profile from '../components/Profile';
import Spinner from '../components/Spinner';

// import '../assets/css/common.css';

class ProfileContainer extends React.Component {
    constructor(props) {
        super(props);
        const { load, match } = props;
        const { params } = match;
        load(params.profileId);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    onDownloadProfile = () => {
        const { download, match } = this.props;
        const { params } = match;
        download(params.profileId);
    };

    onShortlistProfile = (profileId, remove = false) => {
        const { shortlist, removeShortlist } = this.props;
        if (remove) {
            return removeShortlist(profileId);
        }
        return shortlist(profileId);
    };

    render() {
        const { viewProfile, authed } = this.props;
        if (!viewProfile) return <Spinner />;
        return <Profile data={viewProfile} authed={authed} handleDownload={this.onDownloadProfile} handleShortlist={this.onShortlistProfile} />;
    }
}

const mapStateToProps = state => ({
    viewProfile: state.profile.viewProfile,
    authed: state.authed,
});

const mapDispatchToProps = dispatch => ({
    load: id => dispatch(viewProfile(id)),
    download: id => dispatch(downloadProfile(id)),
    shortlist: id => dispatch(addShortlist(id)),
    removeShortlist: id => dispatch(removeShortlist(id)),
});

ProfileContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProfileContainer);
export default ProfileContainer;
