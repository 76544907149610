import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import {
    renderSelectField,
    renderTextAreaField,
} from '../utils/ReduxFormUtils';

import { getPersonalProfile } from '../actions/RegisterActions';

export const validate = (values) => {
    const errors = {};
    if (!values.height) {
        errors.height = 'Required';
    }
    if (!values.weight) {
        errors.weight = 'Required';
    }
    if (!values.physicalStatus) {
        errors.physicalStatus = 'Required';
    }
    if (!values.maritalStatus) {
        errors.maritalStatus = 'Required';
    }
    return errors;
};

class PersonalForm extends Component {
    componentWillMount() {
        const { load } = this.props;
        load();
    }

    moveBack = () => {
        const { match, moveBack } = this.props;
        const { params } = match;
        moveBack({ currentPage: 'personal', mode: params.mode });
    };

    render() {
        const { handleSubmit, submitting } = this.props;

        return (
            <div className="row">
                <div className="heading text-center">
                    <h3>Step 2/6: Add your personal info</h3>
                </div>
                <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="col-md-10 col-md-offset-1">
                        <div className="row">
                            <div className="col-md-6 col-xs-12 marb10">
                                <Field
                                    name="height"
                                    className="form-control"
                                    component={renderSelectField}
                                    label="Height"
                                >
                                    <option value="">Choose</option>
                                    <option value="122">4ft 0in - 122cm</option>
                                    <option value="134">4ft 5in - 134cm</option>
                                    <option value="137">4ft 6in - 137cm</option>
                                    <option value="139">4ft 7in - 139cm</option>
                                    <option value="142">4ft 8in - 142cm</option>
                                    <option value="144">4ft 9in - 144cm</option>
                                    <option value="147">
                                        4ft 10in - 147cm
                                    </option>
                                    <option value="149">
                                        4ft 11in - 149cm
                                    </option>
                                    <option value="152">5ft 0in - 152cm</option>
                                    <option value="154">5ft 1in - 154cm</option>
                                    <option value="157">5ft 2in - 157cm</option>
                                    <option value="160">5ft 3in - 160cm</option>
                                    <option value="162">5ft 4in - 162cm</option>
                                    <option value="165">5ft 5in - 165cm</option>
                                    <option value="167">5ft 6in - 167cm</option>
                                    <option value="170">5ft 7in - 170cm</option>
                                    <option value="172">5ft 8in - 172cm</option>
                                    <option value="175">5ft 9in - 175cm</option>
                                    <option value="177">
                                        5ft 10in - 177cm
                                    </option>
                                    <option value="180">
                                        5ft 11in - 180cm
                                    </option>
                                    <option value="182">6ft 0in - 182cm</option>
                                    <option value="185">6ft 1in - 185cm</option>
                                    <option value="187">6ft 2in - 187cm</option>
                                    <option value="190">6ft 3in - 190cm</option>
                                    <option value="193">6ft 4in - 193cm</option>
                                    <option value="195">6ft 5in - 195cm</option>
                                    <option value="198">6ft 6in - 198cm</option>
                                    <option value="200">6ft 7in - 200cm</option>
                                    <option value="203">6ft 8in - 203cm</option>
                                    <option value="205">6ft 9in - 205cm</option>
                                    <option value="208">
                                        6ft 10in - 208cm
                                    </option>
                                    <option value="210">
                                        6ft 11in - 210cm
                                    </option>
                                    <option value="213">7ft 0in - 213cm</option>
                                </Field>
                            </div>
                            <div className="col-md-6 col-xs-12 marb10">
                                <Field
                                    name="bodyType"
                                    className="form-control"
                                    component={renderSelectField}
                                    label="Body Type"
                                >
                                    <option value="">Choose</option>
                                    <option value="Slim">Slim</option>
                                    <option value="Fat">Fat</option>
                                    <option value="Normal">Normal</option>
                                </Field>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 marb10">
                                <Field
                                    name="maritalStatus"
                                    className="form-control"
                                    component={renderSelectField}
                                    label="Marital Status"
                                >
                                    <option value="">Choose</option>
                                    <option value="Never Married">
                                        Never Married
                                    </option>
                                    <option value="Widowed">Widowed</option>
                                    <option value="Divorced">Divorced</option>
                                </Field>
                            </div>
                            <div className="col-md-6 col-xs-12 marb10">
                                <Field
                                    name="physicalStatus"
                                    className="form-control"
                                    component={renderSelectField}
                                    label="Physical Status"
                                >
                                    <option value="">Choose</option>
                                    <option value="Normal">Normal</option>
                                    <option value="Physically Challenged">
                                        Physically Challenged
                                    </option>
                                </Field>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 marb10">
                                <Field
                                    name="complexion"
                                    className="form-control"
                                    component={renderSelectField}
                                    label="Complexion"
                                >
                                    <option value="">Choose</option>
                                    <option value="Very Fair">Very Fair</option>
                                    <option value="Fair">Fair</option>
                                    <option value="Wheatish">Wheatish</option>
                                    <option value="Wheatish Brown">
                                        Wheatish Brown
                                    </option>
                                    <option value="Dark">Dark</option>
                                </Field>
                            </div>
                            <div className="col-md-6 col-xs-12 marb10">
                                <Field
                                    name="foodHabits"
                                    className="form-control"
                                    component={renderSelectField}
                                    label="Food Habits"
                                >
                                    <option value="">Choose</option>
                                    <option value="Only Veg">Only Veg</option>
                                    <option value="Veg / Non-Veg">
                                        Veg / Non-Veg
                                    </option>
                                </Field>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12">
                                <Field
                                    name="expectation"
                                    className="form-control"
                                    component={renderTextAreaField}
                                    label="Expectations"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="onboard-next-container col-xs-12 text-right">
                        <button
                            type="submit"
                            className="btn btn-primary save onboard-next-button"
                            disabled={submitting}
                        >
                            {submitting ? 'Submitting...' : 'Update Personal'}
                        </button>
                        <button
                            className="btn btn-default onboard-back-button pull-left"
                            onClick={this.moveBack}
                            type="button"
                        >
                            Back
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

const reduxFormDecorator = reduxForm({
    form: 'personalProfile',
    validate,
});

// You have to connect() to any reducers that you wish to connect to yourself
const reduxConnector = connect(
    (state) => ({
        initialValues: state.registration.personal, // pull initial values from account reducer
    }),
    { load: getPersonalProfile }, // bind account loading action creator
);

export default reduxConnector(reduxFormDecorator(PersonalForm));
