import React from 'react';
import { Field as ReduxFormField } from 'redux-form';

export const Field = props => <ReduxFormField autoComplete={`new-${props.name}`} {...props} />;

// {touched && ((error || warning) && <span className="help-block">{error}</span>)}
export const renderField = ({
    input, label, type, className, placeholder, meta: { touched, error, warning },
}) => (
    <div className={error && touched ? 'form-group has-error' : 'form-group'}>
        <label className="control-label">{label}</label>
        <input {...input} className={className} placeholder={placeholder} type={type} />
    </div>
);

export const renderTextAreaField = ({
    input, label, type, className, placeholder, meta: { touched, error, warning },
}) => (
    <div className={error && touched ? 'form-group has-error' : 'form-group'}>
        <label className="control-label">{label}</label>
        <textarea {...input} className={className} placeholder={placeholder} type={type} />
    </div>
);

export const renderSelectField = ({
    input, label, children, type, className, placeholder, meta: { touched, error, warning },
}) => (
    <div className={error && touched ? 'form-group has-error' : 'form-group'}>
        <label className="control-label">{label}</label>
        <select {...input} className={className}>
            {children}
        </select>
    </div>
);

export const normalizePhone = (value, previousValue) => {
    if (!value) {
        return value;
    }
    return value.replace(/[^\d]/g, '');
    /*
  const onlyNums = value.replace(/[^\d]/g, '')
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return onlyNums + '-'
    }
    if (onlyNums.length === 6) {
      return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3) + '-'
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 6) {
    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3)
  }
  return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6, 10)
  */
};
