import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { renderField, renderSelectField } from '../utils/ReduxFormUtils';
import {
    getHoroProfile,
    uploadHoroscope,
    getProfileHoro,
} from '../actions/RegisterActions';
import { STAR, RAASI, CDN_URL } from '../constants/Constants';
import TimePicker from './TimePicker';

import horo from '../assets/images/horo.png';

export const validate = (values) => {
    const errors = {};
    if (!values.tob_hr || !values.tob_min || !values.tob_ampm) {
        errors.tob = 'Required';
    }
    if (!values.placeBirth) {
        errors.placeBirth = 'Required';
    }
    if (!values.raasi) {
        errors.raasi = 'Required';
    }
    if (!values.birthStar) {
        errors.birthStar = 'Required';
    }
    return errors;
};

class HoroscopeForm extends Component {
    componentWillMount() {
        const { load, loadHoro } = this.props;
        load();
        loadHoro();
    }

    handleChange = (e) => {
        const { uploadHoro } = this.props;
        uploadHoro({ horoscope: e.target.files });
    };

    moveBack = () => {
        const { match, moveBack } = this.props;
        const { params } = match;
        moveBack({ currentPage: 'horoscope', mode: params.mode });
    };

    render() {
        const {
            handleSubmit,
            submitting,
            initialHoroAttachValues,
        } = this.props;

        return (
            <div className="row">
                <div className="heading text-center">
                    <h3>Step 6/6: Add your astro info</h3>
                </div>
                <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="col-md-10 col-md-offset-1">
                        <div className="row">
                            <div className="col-md-6 col-xs-12 marb10">
                                <TimePicker label="Time of Birth" id="tob" />
                            </div>
                            <div className="col-md-6 col-xs-12 marb10">
                                <Field
                                    name="placeBirth"
                                    type="text"
                                    className="form-control"
                                    component={renderField}
                                    label="Place of Birth"
                                    placeholder="Your place of birth"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-xs-12 marb10">
                                <Field
                                    name="raasi"
                                    className="form-control"
                                    component={renderSelectField}
                                    label="Raasi"
                                >
                                    <option value="">Choose</option>
                                    {RAASI.map((r, i) => (
                                        <option key={i} value={r.t}>{`${r.t} (${
                                            r.e
                                        })`}</option>
                                    ))}
                                </Field>
                            </div>
                            <div className="col-md-4 col-xs-12 marb10">
                                <Field
                                    name="birthStar"
                                    className="form-control"
                                    component={renderSelectField}
                                    label="Birth Star"
                                >
                                    <option value="">Choose</option>
                                    {STAR.map((c, i) => (
                                        <option key={i} value={c.t}>{`${c.t} (${
                                            c.e
                                        })`}</option>
                                    ))}
                                </Field>
                            </div>
                            <div className="col-md-4 col-xs-12 marb10">
                                <Field
                                    name="birthStarPadham"
                                    className="form-control"
                                    component={renderSelectField}
                                    label="Padham"
                                >
                                    <option value="">Choose</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                </Field>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 marb10">
                                <Field
                                    name="dhosam"
                                    type="text"
                                    className="form-control"
                                    component={renderField}
                                    label="Dhosam"
                                    placeholder="Dhosam (if any)"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="horoscope">Horoscope</label>
                                    <input
                                        type="file"
                                        className="form-control-file"
                                        accept="image/png, image/jpeg, image/gif, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        onChange={this.handleChange}
                                        id="horoscope"
                                    />
                                    <small className="form-text text-muted">
                                        Upload your Horoscope.
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-xs-6 marb10">
                                {initialHoroAttachValues &&
                                    initialHoroAttachValues.attachments &&
                                    initialHoroAttachValues.attachments.map(
                                        (h, i) => (
                                            <a
                                                key={h.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={`${CDN_URL}${h.url}`}
                                                className="list-group-item"
                                            >
                                                <img
                                                    src={horo}
                                                    alt="Horoscope"
                                                />{' '}
                                                Horoscope {i + 1}
                                            </a>
                                        ),
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className="onboard-next-container col-xs-12 text-right">
                        <button
                            type="submit"
                            className="btn btn-primary save onboard-next-button"
                            disabled={submitting}
                        >
                            {submitting ? 'Submitting...' : 'Update Horoscope'}
                        </button>
                        <button
                            className="btn btn-default onboard-back-button pull-left"
                            onClick={this.moveBack}
                        >
                            Back
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

const reduxFormDecorator = reduxForm({
    form: 'profileHoroscope',
    validate,
});

// You have to connect() to any reducers that you wish to connect to yourself
const reduxConnector = connect(
    (state) => ({
        initialValues: state.registration.horo, // pull initial values from account reducer
        initialHoroAttachValues: state.registration.horoAttach,
    }),
    {
        load: getHoroProfile,
        loadHoro: getProfileHoro,
        uploadHoro: uploadHoroscope,
    }, // bind account loading action creator
);

export default reduxConnector(reduxFormDecorator(HoroscopeForm));
