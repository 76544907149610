import React from 'react';
import { connect } from 'react-redux';
import '../assets/css/upgrade.css';
import { paymentLinks } from '../constants/Constants';
import silverPackagePoster from '../assets/images/silver-package-poster.jpeg';
import goldPackagePoster from '../assets/images/gold-package-poster.jpeg';
import diamondPackagePoster from '../assets/images/diamond-package-poster.jpeg';

const Upgrade = (props) => {
    const { username, mid } = props;
    return (
        <div>
            <div className="container main heading text-center">
                <h1>Upgrade</h1>
                <div className="row">
                    <div className="alert alert-info">
                        <p>
                            To view full profile and contact details you will
                            have to upgrade for paid membership plan.
                        </p>
                        <p>
                            முழு profile and contact விவரங்களைக் காண நீங்கள்
                            கட்டண செலுத்த வேண்டும்.
                        </p>
                    </div>
                </div>
            </div>
            <div id="offer-poster-wrapper">
                <img
                    src={silverPackagePoster}
                    className="offer-poster"
                    alt="Silver Package"
                />
                <img
                    src={goldPackagePoster}
                    className="offer-poster"
                    alt="Gold Package"
                />
                <img
                    src={diamondPackagePoster}
                    className="offer-poster"
                    alt="Diamond Package"
                />
            </div>
            <div id="upgrade">
                <div className="col col-12">
                    <h3>Payment Options</h3>
                    <div className="row">
                        <div className="col col-xs-12 col-sm-6 col-md-4">
                            <h5>Bank Transfer</h5>
                            <ul>
                                <li>
                                    <strong>Name:</strong>
                                    <p>SONIYA Y</p>
                                </li>
                                <li>
                                    <strong>A/C No:</strong>
                                    <p>50100125028281</p>
                                </li>
                                <li>
                                    <strong>Bank:</strong>
                                    <p>HDFC Bank</p>
                                </li>
                                <li>
                                    <strong>Branch:</strong>
                                    <p>CHITLAPAKKAM-CHENNAI</p>
                                </li>
                                <li>
                                    <strong>IFSC Code:</strong>
                                    <p>HDFC0000260</p>
                                </li>
                            </ul>
                        </div>
                        <div className="col col-xs-12 col-sm-6 col-md-4">
                            <h5>GPay / Phonepe</h5>
                            <ul>
                                <li>+91 96771 17048</li>
                            </ul>
                        </div>
                        <div className="col col-xs-12 col-sm-6 col-md-4">
                            <h5>Credit Card / Debit Card</h5>
                            <ul>
                                {paymentLinks.map((l, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <li key={`payment-${index}`}>
                                        <a
                                            href={l.link}
                                            rel="noopener noreferrer"
                                        >
                                            {l.link}
                                        </a>
                                        <span style={{ marginLeft: 5 }}>
                                            {l.text}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container text-center">
                <div className="row">
                    <div className="alert alert-success">
                        <p>
                            After payment, please send your payment reference to
                            below WhatsApp number.
                        </p>
                        <p>
                            கட்டணம் செலுத்திய பிறகு, தயவுசெய்து உங்கள் கட்டண
                            குறிப்பை கீழே உள்ள WhatsApp number க்கு அனுப்பவும்.
                        </p>
                        <p>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://api.whatsapp.com/send?phone=919600029979&text=Hi%20Tamilmala%2C%20Help%20approve%20my%20profile%20${username}%20(${mid}).%20Thank%20you.`}
                                className="btn btn-default btn-lg"
                            >
                                <i
                                    className="fa fa-whatsapp fa-lg"
                                    aria-hidden="true"
                                />
                                &nbsp;96000 29979
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const reduxConnector = connect((state) => ({
    username: state.authed.authJWT.user_name,
    mid: state.authed.authJWT.mid,
}));

export default reduxConnector(Upgrade);
