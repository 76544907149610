import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import {
    renderField,
    renderTextAreaField,
    renderSelectField,
} from '../utils/ReduxFormUtils';

import { getBasicProfile } from '../actions/RegisterActions';
import {
    STATE,
    COMMUNITY,
    TAMILMALA_CASTES,
    TAMILMALA_LANGUAGES,
    TAMILMALA_SUBCASTES,
} from '../constants/Constants';

import DateOfBirth from './DateOfBirth';

export const validate = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = 'Required';
    }
    if (!values.gender) {
        errors.gender = 'Required';
    }
    if (!values.dob_month || !values.dob_date || !values.dob_year) {
        errors.dob_month = 'Required';
    }
    if (!values.surname) {
        errors.surname = 'Required';
    }
    if (!values.community) {
        errors.community = 'Required';
    }
    if (!values.mobile) {
        errors.mobile = 'Required';
    }
    if (values.email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(values.email).toLowerCase())) {
            errors.email = 'Invalid email address';
        }
    }
    if (!values.country) {
        errors.country = 'Required';
    }
    if (!values.state) {
        errors.state = 'Required';
    }
    if (!values.city) {
        errors.city = 'Required';
    }
    if (!values.pincode) {
        errors.pincode = 'Required';
    }
    return errors;
};

class BasicForm extends Component {
    componentWillMount() {
        const { load } = this.props;
        load();
    }

    render() {
        const {
            handleSubmit,
            submitting,
            initialValues,
            userDomain,
            language,
            community,
        } = this.props;
        const isTamilmala = userDomain === 'tamilmala';
        return (
            <div className="row">
                <div className="heading text-center">
                    <h3>Step 1/6: Add your basic info</h3>
                </div>
                <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="col-md-10 col-md-offset-1">
                        {initialValues && initialValues.mid && (
                            <div style={{ marginBottom: 30 }}>
                                <h3>{initialValues.mid}</h3>
                            </div>
                        )}
                        <Field
                            name="name"
                            type="text"
                            className="form-control"
                            component={renderField}
                            label="Bride/Groom Name"
                            placeholder="Enter Bride/Groom name"
                        />
                        <div className="row">
                            <div className="col-md-6 col-xs-12 marb10">
                                <Field
                                    name="gender"
                                    className="form-control"
                                    component={renderSelectField}
                                    label="Gender"
                                >
                                    <option value="">Choose</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </Field>
                            </div>
                            <div className="col-md-6 col-xs-12 marb10">
                                <DateOfBirth label="Date of Birth" id="dob" />
                            </div>
                        </div>
                        <div className="row">
                            {!isTamilmala ? (
                                <div className="col-md-6 col-xs-12 marb10">
                                    <Field
                                        name="surname"
                                        className="form-control"
                                        component={renderField}
                                        type="text"
                                        label="Surname / Annav"
                                        placeholder="Your Surname / Annav"
                                    />
                                </div>
                            ) : null}
                            {isTamilmala ? (
                                <div className="col-md-6 col-xs-12 marb10">
                                    <Field
                                        name="language"
                                        className="form-control"
                                        component={renderSelectField}
                                        type="text"
                                        label="Language"
                                    >
                                        <option value="">Choose</option>
                                        {TAMILMALA_LANGUAGES.map((e, i) => (
                                            <option key={i} value={e}>
                                                {e}
                                            </option>
                                        ))}
                                    </Field>
                                </div>
                            ) : null}
                            {(isTamilmala && language) || !isTamilmala ? (
                                <div className="col-md-6 col-xs-12 marb10">
                                    <Field
                                        name="community"
                                        className="form-control"
                                        component={renderSelectField}
                                        type="text"
                                        label={
                                            isTamilmala ? 'Caste' : 'Community'
                                        }
                                    >
                                        <option value="">Choose</option>
                                        {(isTamilmala
                                            ? TAMILMALA_CASTES.filter((c) =>
                                                  c.languages.includes(
                                                      language,
                                                  ),
                                              )
                                            : COMMUNITY
                                        ).map((e) => (
                                            <option
                                                key={e.value}
                                                value={e.value}
                                            >
                                                {e.label}
                                            </option>
                                        ))}
                                    </Field>
                                </div>
                            ) : null}
                            {isTamilmala && language && community ? (
                                <div className="col-md-6 col-xs-12 marb10">
                                    <Field
                                        name="surname"
                                        className="form-control"
                                        component={renderSelectField}
                                        type="text"
                                        label="Subcaste"
                                    >
                                        <option value="">Choose</option>
                                        {(
                                            TAMILMALA_SUBCASTES[community] || []
                                        ).map((e) => (
                                            <option
                                                key={e.value}
                                                value={e.value}
                                            >
                                                {e.label}
                                            </option>
                                        ))}
                                    </Field>
                                </div>
                            ) : null}
                            {isTamilmala ? (
                                <div className="col-md-6 col-xs-12 marb10">
                                    <Field
                                        name="gothram"
                                        className="form-control"
                                        component={renderField}
                                        type="text"
                                        label="Gothram"
                                        placeholder="Your gothram"
                                    />
                                </div>
                            ) : null}
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 marb10">
                                <Field
                                    name="mobile"
                                    className="form-control"
                                    component={renderField}
                                    type="text"
                                    label="Mobile"
                                    placeholder="Your mobile number"
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 marb10">
                                <Field
                                    name="mobileAlternate"
                                    className="form-control"
                                    component={renderField}
                                    type="text"
                                    label="Alternate Mobile"
                                    placeholder="Your alternate mobile number"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-xs-12 marb10">
                                <Field
                                    name="email"
                                    className="form-control"
                                    component={renderField}
                                    type="text"
                                    label="Email"
                                    placeholder="Your email"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 marb10">
                                <Field
                                    name="country"
                                    className="form-control"
                                    component={renderSelectField}
                                    label="Country"
                                >
                                    <option value="">Choose</option>
                                    <option value="India">India</option>
                                    <option value="Others">Others</option>
                                </Field>
                            </div>
                            <div className="col-md-6 col-xs-12 marb10">
                                <Field
                                    name="state"
                                    className="form-control"
                                    component={renderSelectField}
                                    type="text"
                                    label="State"
                                >
                                    <option value="">Choose</option>
                                    {STATE.map((e, i) => (
                                        <option key={i} value={e}>
                                            {e}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-xs-12 marb10">
                                <Field
                                    name="addressHome"
                                    className="form-control"
                                    component={renderTextAreaField}
                                    label="Address"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 marb10">
                                <Field
                                    name="city"
                                    className="form-control"
                                    component={renderField}
                                    type="text"
                                    label="City"
                                    placeholder="Your city"
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 marb10">
                                <Field
                                    name="pincode"
                                    className="form-control"
                                    component={renderField}
                                    type="text"
                                    label="Pincode"
                                    placeholder="Your pincode"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="onboard-next-container col-xs-12 text-right">
                        <button
                            type="submit"
                            className="btn btn-primary save onboard-next-button"
                            disabled={submitting}
                        >
                            {submitting ? 'Submitting...' : 'Update Basic'}
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

const reduxFormDecorator = reduxForm({
    form: 'basicProfile',
    validate,
});

const selector = formValueSelector('basicProfile');
const reduxConnector = connect(
    (state) => ({
        initialValues: state.registration.basic, // pull initial values from account reducer
        userDomain: state.authed.user.domain,
        language: selector(state, 'language'),
        community: selector(state, 'community'),
    }),
    { load: getBasicProfile }, // bind account loading action creator
);

export default reduxConnector(reduxFormDecorator(BasicForm));
