import React from 'react';
import { connect } from 'react-redux';
import { push } from '../utils/history';

import { getProfileDownloadList, viewMoreProfileDownloadList } from '../actions/ProfileActions';

class ProfileDownloadList extends React.Component {
    componentWillMount() {
        const { load, profileDownloadListPage: p } = this.props;
        load(p);
    }

    componentWillReceiveProps(nextProps) {
        const { profileDownloadListPage: p1, isFetching1 } = nextProps;
        const { load, profileDownloadListPage: p2, isFetching2 } = this.props;
        if (p1 !== p2 || isFetching1 !== isFetching2) {
            load(p1);
        }
    }

    convertDate = (inputDate) => {
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const d = new Date(inputDate);
        return [d.getDate(), monthNames[d.getMonth()], d.getFullYear()].join(' ');
    };

    moveNextPage = () => {
        const { viewMore } = this.props;
        viewMore();
    };

    viewProfile = (pid) => {
        const { viewProfile } = this.props;
        viewProfile(pid);
    };

    render() {
        const { data } = this.props;
        const profileDownloads = [];
        if (data) {
            data.forEach((p, i) => {
                profileDownloads.push(
                    <tr key={i}>
                        <td>{p.downloaded_mid}</td>
                        <td>{p.downloaded_name}</td>
                        <td>{this.convertDate(p.viewed_at)}</td>
                        <td>
                            <button
                                type="button"
                                onClick={() => {
                                    this.viewProfile(p.downloaded_user_id);
                                }}
                                className="btn btn-default btn-xs"
                            >
                                View Profile
                            </button>
                        </td>
                    </tr>,
                );
            });
        }
        return (
            <table className="table table-hover table-condensed profile-download-list">
                <thead>
                    <tr>
                        <th>ProfileId</th>
                        <th>Name</th>
                        <th>Viewed At</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>{profileDownloads}</tbody>
            </table>
        );
    }
}

const mapStateToProps = state => ({
    profileDownloadList: state.profile.profileDownloadList,
    profileDownloadListPage: state.profile.profileDownloadListPage,
});

const mapDispatchToProps = dispatch => ({
    load: (p) => {
        dispatch(getProfileDownloadList(p));
    },
    viewMore: () => {
        dispatch(viewMoreProfileDownloadList());
    },
    viewProfile: (id) => {
        dispatch(push(`/profile/view/${id}`));
    },
});

const reduxConnector = connect(
    mapStateToProps,
    mapDispatchToProps,
);
export default reduxConnector(ProfileDownloadList);
