import React, { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, reset, Field } from 'redux-form';

import isEqual from 'lodash/isEqual';

import {
    getProfileList,
    viewMoreProfileList,
    updateProfileStatus,
    updateProfileFeatured,
    updateProfileListFilter,
    resetProfileListFilter,
} from '../actions/AdminActions';

import Spinner from '../components/Spinner';
import DateOfBirth from '../components/DateOfBirth';
import AdminProfileList from '../components/AdminProfileListV2';
import { renderField, renderSelectField } from '../utils/ReduxFormUtils';

// import '../assets/css/common.css';

class AdminProfileListFilter extends PureComponent {
    render() {
        const { handleSubmit, onReset } = this.props;
        return (
            <form
                onSubmit={handleSubmit}
                autoComplete="off"
                className="form-inline"
            >
                <div className="panel panel-default">
                    <div className="panel-body">
                        <Field
                            name="mid"
                            type="text"
                            className="form-control"
                            component={renderField}
                            placeholder="MM / MF id"
                        />
                        <DateOfBirth id="dob" />
                        <Field
                            name="domain"
                            className="form-control"
                            component={renderSelectField}
                            label="Domain"
                        >
                            <option value="marathimala">Marathimala</option>
                            <option value="tamilmala">Tamilmala</option>
                        </Field>
                        <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ marginRight: 5, marginLeft: 10 }}
                        >
                            Search
                        </button>
                        <button
                            className="btn btn-default"
                            type="button"
                            onClick={onReset}
                        >
                            Reset
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

// Decorate the form component
AdminProfileListFilter = reduxForm({
    form: 'adminSearch',
})(AdminProfileListFilter);

class AdminProfileListContainer extends Component {
    componentWillMount() {
        const { load, profileListFilter: f, profileListPage: p } = this.props;
        load(f, p);
    }

    componentWillReceiveProps(nextProps) {
        const {
            profileListFilter: f1,
            profileListPage: p1,
            isFetching1,
        } = nextProps;
        const {
            load,
            profileListFilter: f2,
            profileListPage: p2,
            isFetching2,
        } = this.props;
        if (
            !isEqual(f1.sort(), f2.sort()) ||
            p1 !== p2 ||
            isFetching1 !== isFetching2
        ) {
            load(f1, p1);
        }
    }

    handleFilterSubmit = (values) => {
        const { updateProfileListFilter } = this.props;

        updateProfileListFilter(
            'domain',
            values.domain || 'tamilmala',
            true,
            false,
        );

        if (values.dob_month && values.dob_year && values.dob_date) {
            const date_of_birth = [
                values.dob_year,
                values.dob_month,
                values.dob_date,
            ].join('-');
            updateProfileListFilter(
                'date_of_birth',
                date_of_birth,
                true,
                false,
            );
        }

        if (values.mid) {
            updateProfileListFilter('mid', values.mid, true, false);
        }
    };

    resetFilter = () => {
        const { resetProfileListFilter } = this.props;
        resetProfileListFilter();
    };

    moveNextPage = (e) => {
        const { viewMore } = this.props;
        e.preventDefault();
        viewMore();
    };

    render() {
        const {
            profileList: l,
            isFetching,
            updateStatus,
            updateFeatured,
        } = this.props;
        return (
            <div className="container">
                <div className="row">
                    <AdminProfileListFilter
                        onSubmit={this.handleFilterSubmit}
                        onReset={this.resetFilter}
                    />
                </div>
                <div className="row">
                    {!isFetching && l.rows.length === 0 ? (
                        <p>Empty</p>
                    ) : (
                        <AdminProfileList
                            data={l.rows}
                            updateStatus={updateStatus}
                            updateFeatured={updateFeatured}
                        />
                    )}
                    {isFetching && <Spinner />}
                    {!isFetching && l.rows.length > 0 && (
                        <nav>
                            <ul className="pager">
                                <li>
                                    <a href="/" onClick={this.moveNextPage}>
                                        View more
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    profileList: state.admin.profileList,
    profileListPage: state.admin.profileListPage,
    profileListFilter: state.admin.profileListFilter,
    isFetching: state.admin.isFetching,
});

const mapDispatchToProps = (dispatch) => ({
    load: (f, p) => {
        dispatch(getProfileList(f, p));
    },
    viewMore: () => {
        dispatch(viewMoreProfileList());
    },
    updateStatus: (i, s) => {
        dispatch(updateProfileStatus(i, s));
    },
    updateFeatured: (i, s) => {
        dispatch(updateProfileFeatured(i, s));
    },
    updateProfileListFilter: (n, v, c, m) => {
        dispatch(updateProfileListFilter(n, v, c, m));
    },
    resetProfileListFilter: () => {
        dispatch(resetProfileListFilter());
        dispatch(reset('adminSearch'));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminProfileListContainer);
