import * as types from '../constants/ActionTypes';

const initialState = { step: 0, isComplete: false, profileId: '' };

export default function registration(state = initialState, action) {
    switch (action.type) {
    case types.REGISTRATION_STATUS:
        return Object.assign({}, state, {
            step: action.step,
            isComplete: action.isComplete,
        });
    case types.REGISTRATION_PROFILE_ID:
        return Object.assign({}, state, {
            profileId: action.profileId,
        });
    case types.GET_BASIC_PROFILE:
        return Object.assign({}, state, {
            basic: action.basic,
        });
    case types.GET_PERSONAL_PROFILE:
        return Object.assign({}, state, {
            personal: action.personal,
        });
    case types.GET_WORK_PROFILE:
        return Object.assign({}, state, {
            work: action.work,
        });
    case types.GET_FAMILY_PROFILE:
        return Object.assign({}, state, {
            family: action.family,
        });
    case types.GET_HORO_PROFILE:
        return Object.assign({}, state, {
            horo: action.horo,
        });
    case types.GET_PHOTO_PROFILE:
        return Object.assign({}, state, {
            photo: action.photo,
        });
    case types.UPLOAD_HORO_ATTACH:
        return Object.assign({}, state, {
            horoAttach: action.horoAttach,
        });
    default:
        return state;
    }
}
