import React from 'react';
import { connect } from 'react-redux';

import Subscription from '../components/Subscription';
import ProfileDownloadList from '../components/ProfileDownloadList';
import Spinner from '../components/Spinner';

import { getProfileDownloadList, getSubscription, viewMoreProfileDownloadList } from '../actions/ProfileActions';

class SubscriptionContainer extends React.Component {
    componentWillMount() {
        const { load, profileDownloadListPage: p, getSub } = this.props;
        getSub();
        load(p);
    }

    componentWillReceiveProps(nextProps) {
        const { profileDownloadListPage: p1, isFetching1 } = nextProps;
        const { load, profileDownloadListPage: p2, isFetching2 } = this.props;
        if (p1 !== p2 || isFetching1 !== isFetching2) {
            load(p1);
        }
    }

    moveNextPage = (e) => {
        const { viewMore } = this.props;
        e.preventDefault();
        viewMore();
    };

    render() {
        const { profileDownloadList: l, sub: s, isFetching } = this.props;
        return (
            <div className="container">
                <div className="page-header">
                    <h2>Subscription Details</h2>
                </div>
                <div className="row">
                    <Subscription data={s} />
                </div>
                <div className="row">
                    <div className="page-header">
                        <h4>Already viewed Profiles</h4>
                    </div>
                    <div className="col-md-8 col-md-offset-2">
                        {!isFetching && l.length === 0 ? <p>Empty</p> : <ProfileDownloadList data={l} />}
                        {isFetching && <Spinner />}
                        {!isFetching && l.length > 0 && (
                            <nav>
                                <ul className="pager">
                                    <li>
                                        <a href="/" onClick={this.moveNextPage}>
                                            View more
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    sub: state.profile.subscriptionData,
    profileDownloadList: state.profile.profileDownloadList,
});

const mapDispatchToProps = dispatch => ({
    getSub: () => {
        dispatch(getSubscription());
    },
    load: (p) => {
        dispatch(getProfileDownloadList(p));
    },
    viewMore: () => {
        dispatch(viewMoreProfileDownloadList());
    },
});

SubscriptionContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SubscriptionContainer);
export default SubscriptionContainer;
