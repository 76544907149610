import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import {
    renderField,
    renderTextAreaField,
    renderSelectField,
} from '../utils/ReduxFormUtils';

import { getFamilyProfile } from '../actions/RegisterActions';

export const validate = (values) => {
    const errors = {};
    if (!values.fatherName) {
        errors.fatherName = 'Required';
    }
    if (!values.fatherAlive) {
        errors.fatherAlive = 'Required';
    }
    if (!values.motherName) {
        errors.motherName = 'Required';
    }
    if (!values.motherAlive) {
        errors.motherAlive = 'Required';
    }
    if (!values.motherAlive) {
        errors.motherAlive = 'Required';
    }
    return errors;
};

class FamilyForm extends Component {
    componentWillMount() {
        const { load } = this.props;
        load();
    }

    moveBack = () => {
        const { match, moveBack } = this.props;
        const { params } = match;
        moveBack({ currentPage: 'family', mode: params.mode });
    };

    render() {
        const { handleSubmit, submitting } = this.props;
        return (
            <div className="row">
                <div className="heading text-center">
                    <h3>Step 5/6: Add your family info</h3>
                </div>
                <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="col-md-10 col-md-offset-1">
                        <div className="row">
                            <div className="col-md-8 col-xs-12 marb10">
                                <Field
                                    name="fatherName"
                                    className="form-control"
                                    component={renderField}
                                    type="text"
                                    label="Father's Name"
                                    placeholder="Your father's name"
                                />
                            </div>
                            <div className="col-md-4 col-xs-12 marb10">
                                <Field
                                    name="fatherAlive"
                                    className="form-control"
                                    component={renderSelectField}
                                    label="Father Alive"
                                >
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </Field>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Field
                                    name="fatherOccupation"
                                    className="form-control"
                                    component={renderField}
                                    type="text"
                                    label="Father's Occupation"
                                    placeholder="Your father's occupation"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 col-xs-12 marb10">
                                <Field
                                    name="motherName"
                                    className="form-control"
                                    component={renderField}
                                    type="text"
                                    label="Mother's Name"
                                    placeholder="Your mother's name"
                                />
                            </div>
                            <div className="col-md-4 col-xs-12 marb10">
                                <Field
                                    name="motherAlive"
                                    className="form-control"
                                    component={renderSelectField}
                                    label="Mother Alive"
                                >
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </Field>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Field
                                    name="motherOccupation"
                                    className="form-control"
                                    component={renderField}
                                    type="text"
                                    label="Mother's Occupation"
                                    placeholder="Your mother's occupation"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 marb10">
                                <Field
                                    name="sisters"
                                    className="form-control"
                                    component={renderSelectField}
                                    label="Sisters"
                                >
                                    <option value="0">No</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </Field>
                            </div>
                            <div className="col-md-6 col-xs-12 marb10">
                                <Field
                                    name="sistersMarried"
                                    className="form-control"
                                    component={renderSelectField}
                                    label="Sisters Married"
                                >
                                    <option value="0">No</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </Field>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 marb10">
                                <Field
                                    name="brothers"
                                    className="form-control"
                                    component={renderSelectField}
                                    label="Brothers"
                                >
                                    <option value="0">No</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </Field>
                            </div>
                            <div className="col-md-6 col-xs-12 marb10">
                                <Field
                                    name="brothersMarried"
                                    className="form-control"
                                    component={renderSelectField}
                                    label="Brothers Married"
                                >
                                    <option value="0">No</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </Field>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Field
                                    name="familyStatus"
                                    className="form-control"
                                    component={renderSelectField}
                                    label="Family Status"
                                >
                                    <option value="">Choose</option>
                                    <option value="Lower Middle class">
                                        Lower Middle class{' '}
                                    </option>
                                    <option value="Middle Class">
                                        Middle Class
                                    </option>
                                    <option value="Upper Middle Class">
                                        Upper Middle Class
                                    </option>
                                    <option value="Rich">Rich</option>
                                </Field>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Field
                                    name="aboutFamily"
                                    className="form-control"
                                    component={renderTextAreaField}
                                    label="About Family"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Field
                                    name="propertyDetails"
                                    className="form-control"
                                    component={renderTextAreaField}
                                    label="Property Details"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="onboard-next-container col-xs-12 text-right">
                        <button
                            type="submit"
                            className="btn btn-primary save onboard-next-button"
                            disabled={submitting}
                        >
                            {submitting ? 'Submitting...' : 'Update Family'}
                        </button>
                        <button
                            className="btn btn-default onboard-back-button pull-left"
                            onClick={this.moveBack}
                            type="button"
                        >
                            Back
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

const reduxFormDecorator = reduxForm({
    form: 'familyProfile',
    validate,
});

// You have to connect() to any reducers that you wish to connect to yourself
const reduxConnector = connect(
    (state) => ({
        initialValues: state.registration.family, // pull initial values from account reducer
    }),
    { load: getFamilyProfile }, // bind account loading action creator
);

export default reduxConnector(reduxFormDecorator(FamilyForm));
