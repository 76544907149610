export const CHANGE_IS_MOBILE = 'CHANGE_IS_MOBILE';
export const CHANGE_WIDTH_AND_HEIGHT = 'CHANGE_WIDTH_AND_HEIGHT';

export const RECEIVE_ACCESS_TOKEN = 'RECEIVE_ACCESS_TOKEN';
export const RECEIVE_AUTHED_USER = 'RECEIVE_AUTHED_USER';
export const RESET_AUTHED = 'RESET_AUTHED';
export const CHECKED_AUTHED = 'CHECKED_AUTHED';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';

export const REGISTRATION_STATUS = 'REGISTRATION_STATUS';
export const REGISTRATION_PROFILE_ID = 'REGISTRATION_PROFILE_ID';
export const GET_BASIC_PROFILE = 'GET_BASIC_PROFILE';
export const GET_PERSONAL_PROFILE = 'GET_PERSONAL_PROFILE';
export const GET_WORK_PROFILE = 'GET_WORK_PROFILE';
export const GET_FAMILY_PROFILE = 'GET_FAMILY_PROFILE';
export const GET_HORO_PROFILE = 'GET_HORO_PROFILE';
export const GET_PHOTO_PROFILE = 'GET_PHOTO_PROFILE';
export const UPLOAD_HORO_ATTACH = 'UPLOAD_HORO_ATTACH';

export const FETCHING_DATA = 'FETCHING_DATA';

export const PROFILE_LIST = 'PROFILE_LIST';
export const PROFILE_LIST_VIEW_MORE = 'PROFILE_LIST_VIEW_MORE';
export const PROFILE_LIST_FILTER = 'PROFILE_LIST_FILTER';
export const VIEW_PROFILE = 'VIEW_PROFILE';
export const RESET_PROFILE = 'RESET_PROFILE';
export const DOWNLOAD_PROFILE = 'DOWNLOAD_PROFILE';

export const SHORTLIST_ADD_SUCCESS = 'SHORTLIST_ADD_SUCCESS';
export const SHORTLIST_REMOVE_SUCCESS = 'SHORTLIST_REMOVE_SUCCESS';

export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const PROFILE_DOWNLOAD_LIST = 'PROFILE_DOWNLOAD_LIST';
export const PROFILE_DOWNLOAD_LIST_VIEW_MORE =
    'PROFILE_DOWNLOAD_LIST_VIEW_MORE';

export const ADMIN_FETCHING_DATA = 'ADMIN_FETCHING_DATA';
export const ADMIN_PROFILE_LIST = 'ADMIN_PROFILE_LIST';
export const ADMIN_PROFILE_LIST_VIEW_MORE = 'ADMIN_PROFILE_LIST_VIEW_MORE';
export const ADMIN_PROFILE_STATUS_UPDATE = 'ADMIN_PROFILE_STATUS_UPDATE';
export const ADMIN_PROFILE_FEATURED_UPDATE = 'ADMIN_PROFILE_FEATURED_UPDATE';
export const ADMIN_PROFILE_LIST_FILTER = 'ADMIN_PROFILE_LIST_FILTER';
export const ADMIN_PROFILE_LIST_FILTER_RESET =
    'ADMIN_PROFILE_LIST_FILTER_RESET';
