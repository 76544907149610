import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { logoutUser } from '../actions/AuthedActions';

class Logout extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(logoutUser());
    }

    render() {
        return <Redirect to={{ pathname: '/' }} />;
    }
}
const reduxConnector = connect();

export default reduxConnector(Logout);
