import merge from 'lodash/merge';
import unionBy from 'lodash/unionBy';
import uniqBy from 'lodash/uniqBy';
import filter from 'lodash/filter';
import * as types from '../constants/ActionTypes';

const initialState = {
    subscriptionData: {},
    profileDownloadList: [],
    profileDownloadListPage: 1,
    profileList: { count: 0, rows: [], photo_nudge: false },
    profileListPage: 1,
    profileListFilter: [
        {
            name: 'from_age',
            value: '18',
            isChecked: true,
            isMulti: false,
        },
        {
            name: 'to_age',
            value: '40',
            isChecked: true,
            isMulti: false,
        },
    ],
    viewProfile: null,
};

export default function profile(state = initialState, action) {
    switch (action.type) {
    case types.VIEW_PROFILE:
        return Object.assign({}, state, {
            viewProfile: action.data,
        });
    case types.RESET_PROFILE:
        return Object.assign({}, state, {
            viewProfile: null,
        });
    case types.DOWNLOAD_PROFILE:
        return Object.assign({}, state, {
            viewProfile: merge({}, state.viewProfile, action.data),
        });
    case types.PROFILE_LIST:
        return Object.assign({}, state, {
            profileList: {
                count: action.data.count,
                photo_nudge: action.data.photo_nudge,
                rows: parseInt(action.page, 10) > 1 ? unionBy(state.profileList.rows, action.data.rows, 'profile_id') : action.data.rows,
            },
            isFetching: false,
        });
    case types.PROFILE_LIST_VIEW_MORE:
        return Object.assign({}, state, {
            profileListPage: ++state.profileListPage,
        });
    case types.PROFILE_LIST_FILTER:
        return Object.assign({}, state, {
            profileListFilter: filter(
                uniqBy([action.profileListFilter, ...state.profileListFilter], o => (o.isMulti ? `${o.name}|${o.value}` : o.name)),
                { isChecked: true },
            ),
            profileListPage: 1,
        });
    case types.GET_SUBSCRIPTION:
        return Object.assign({}, state, {
            subscriptionData: action.data,
        });
    case types.PROFILE_DOWNLOAD_LIST:
        return Object.assign({}, state, {
            profileDownloadList: parseInt(action.page, 10) > 1 ? [...state.profileDownloadList, ...action.data] : action.data,
            isFetching: false,
        });
    case types.PROFILE_DOWNLOAD_LIST_VIEW_MORE:
        return Object.assign({}, state, {
            profileDownloadListPage: ++state.profileDownloadListPage,
        });
    case types.SHORTLIST_ADD_SUCCESS:
        return {
            ...state,
            profileList: {
                ...state.profileList,
                rows: state.profileList.rows.map((r) => {
                    if (r.profile_id === action.id) {
                        r.hasShortlisted = true;
                    }
                    return r;
                }),
            },
        };
    case types.SHORTLIST_REMOVE_SUCCESS:
        return {
            ...state,
            profileList: {
                ...state.profileList,
                rows: state.profileList.rows.map((r) => {
                    if (r.profile_id === action.id) {
                        r.hasShortlisted = false;
                    }
                    return r;
                }),
            },
        };
    case types.FETCHING_DATA:
        return Object.assign({}, state, {
            isFetching: true,
        });
    default:
        return state;
    }
}
