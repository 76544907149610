import Cookies from 'js-cookie';
import { toastr } from 'react-redux-toastr';

const COOKIE_PATH = 'accessTokenV2';

export function checkStatus(response) {
    const { status, statusText } = response;

    if (status >= 200 && status < 300) return response;

    const error = new Error(statusText);
    error.response = response;
    throw error;
}

export function handleError(title, ex) {
    if (ex.response) {
        ex.response.json().then((data) => {
            toastr.error(title, data.Message || 'Please try again...');
        });
    }
}

export function parseJSON(response) {
    return response.json();
}

export function setMultiPartPost(body) {
    const accessToken = Cookies.get(COOKIE_PATH);
    const h = {};
    if (accessToken) {
        h.Authorization = `Bearer ${accessToken}`;
    }

    return {
        method: 'POST',
        headers: h,
        body,
    };
}

export function setDeleteHeaders(body, isJsonBody = true) {
    return setPostHeaders(body, isJsonBody, 'DELETE');
}

export function setPostHeaders(body, isJsonBody = true, method = 'POST') {
    const accessToken = Cookies.get(COOKIE_PATH);
    const h = {};
    h.Accept = 'application/json';
    h['Content-Type'] = 'application/json';
    if (accessToken) {
        h.Authorization = `Bearer ${accessToken}`;
    }

    return {
        method,
        headers: h,
        body: isJsonBody ? JSON.stringify(body) : body,
    };
}

export function setGetHeaders() {
    const accessToken = Cookies.get(COOKIE_PATH);
    const h = {};
    h.Accept = 'application/json';
    h['Content-Type'] = 'application/json';
    if (accessToken) {
        h.Authorization = `Bearer ${accessToken}`;
    }

    return {
        method: 'GET',
        headers: h,
    };
}

export function setAuthCookie(token) {
    const inFiftyMinutes = new Date(new Date().getTime() + 50 * 60 * 1000);
    Cookies.set(COOKIE_PATH, token, {
        expires: inFiftyMinutes,
    });
}

export function getJSON(url) {
    return fetch(url, setGetHeaders())
        .then(checkStatus)
        .then(parseJSON);
}

export function postJSON(url, payload) {
    return fetch(url, setPostHeaders(payload))
        .then(checkStatus)
        .then(parseJSON);
}

export function deleteJSON(url, payload) {
    return fetch(url, setDeleteHeaders(payload))
        .then(checkStatus)
        .then(parseJSON);
}
