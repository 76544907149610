import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getSubscription } from '../actions/ProfileActions';

class Subscription extends Component {
    componentWillMount() {
        const { getSubscription } = this.props;
        getSubscription();
    }

    convertDate = (inputDate) => {
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const d = new Date(inputDate);
        return [d.getDate(), monthNames[d.getMonth()], d.getFullYear()].join(' ');
    };

    render() {
        const { data: s } = this.props;
        return (
            <div className="subscription-summary">
                <div className="row">
                    <div className="col-md-3 col-xs-6 col-sm-6 item">
                        <div className="title">TOTAL PROFILES</div>
                        <div className="value">{s.total_download}</div>
                        <small>{s.current_download} profiles downloaded</small>
                    </div>
                    <div className="col-md-3 col-xs-6 col-sm-6 item">
                        <div className="title">PENDING PROFILES</div>
                        <div className="value">{s.total_download - s.current_download}</div>
                    </div>
                    <div className="col-md-3 col-xs-6 col-sm-6 item">
                        <div className="title">START DATE</div>
                        <div className="value">{this.convertDate(s.start_date)}</div>
                        <small>{s.duration} days subscription</small>
                    </div>
                    <div className="col-md-3 col-xs-6 col-sm-6 item">
                        <div className="title">END DATE</div>
                        <div className="value">{this.convertDate(s.end_date)}</div>
                        <small>{s.remaining} days remaining</small>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    getSubscription: () => {
        dispatch(getSubscription());
    },
});

const reduxConnector = connect(
    null,
    mapDispatchToProps,
);
export default reduxConnector(Subscription);
