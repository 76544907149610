import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { uploadMarriagePhoto } from '../actions/AdminActions';
import getDataTransferFiles from '../utils/getDataTransferFiles';

const MarriagesContainer = (props) => {
    const { uploadPhoto } = props;

    const [image, setImage] = useState(null);
    const [caption, setCaption] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);

    const handlePhotoSelect = useCallback((event) => {
        const [file] = getDataTransferFiles(event);
        if (!file) {
            return;
        }
        const previewUrl = window.URL.createObjectURL(file);
        file.preview = previewUrl;
        setImage(file);
    }, []);

    const handleUpload = useCallback(
        (event) => {
            event.preventDefault();
            setLoading(true);
            uploadPhoto(image, caption).then(() => {
                setLoading(false);
                setImage(null);
                setCaption('');
                setSuccess('Photo uploaded successfully');

                setTimeout(() => {
                    setSuccess(null);
                }, 5000);
            });
        },
        [image, caption],
    );

    return (
        <form onSubmit={handleUpload}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minHeight: '70vh',
                    maxWidth: 400,
                    margin: '0 auto',
                }}
            >
                <h3 style={{ margin: '0 0 3rem' }}>Upload marriage photos</h3>
                {success ? (
                    <div
                        className="alert alert-success"
                        role="alert"
                        style={{
                            width: '100%',
                            textAlign: 'center',
                            fontSize: '1.5rem',
                        }}
                    >
                        {success}
                    </div>
                ) : null}
                {image ? (
                    <img
                        src={image.preview}
                        alt={image.name}
                        style={{ maxWidth: 300 }}
                    />
                ) : null}
                <label
                    htmlFor="file"
                    className={image ? 'btn btn-link' : 'btn btn-info'}
                >
                    {image ? 'Change the photo' : 'Select a marriage photo'}
                    <input
                        style={{ display: 'none' }}
                        type="file"
                        id="file"
                        multiple={false}
                        accept="image/png, image/jpeg, image/gif"
                        required
                        onChange={handlePhotoSelect}
                    />
                </label>
                {image ? (
                    <>
                        <div
                            className="form-group"
                            style={{ width: '100%', marginTop: '2rem' }}
                        >
                            <label htmlFor="caption" style={{ width: '100%' }}>
                                Add a caption
                                <input
                                    id="caption"
                                    name="caption"
                                    type="text"
                                    required
                                    className="form-control"
                                    value={caption}
                                    onChange={(e) => {
                                        setCaption(e.target.value);
                                    }}
                                />
                            </label>
                        </div>
                        <button
                            type="submit"
                            className={`btn ${
                                !caption ? 'btn-secondary' : 'btn-primary'
                            }`}
                            disabled={!caption || loading}
                            style={{ marginTop: '1rem' }}
                        >
                            {loading ? 'Loading...' : 'Upload'}
                        </button>
                    </>
                ) : null}
            </div>
        </form>
    );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => ({
    uploadPhoto: (i, c) => {
        return dispatch(uploadMarriagePhoto(i, c));
    },
});

export default React.memo(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(MarriagesContainer),
);
