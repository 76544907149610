import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NavBar from '../components/NavBar';
import logo from '../assets/images/tamilmala-logo.png';
import playstore from '../assets/images/playstore.png';

const propTypes = {
    isMobile: PropTypes.bool,
};

class NavContainer extends PureComponent {
    render() {
        return (
            <div>
                <NavBar {...this.props} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { authed, environment } = state;
    const { isMobile } = environment;

    return { authed, isMobile };
}

NavContainer.propTypes = propTypes;

export class MobileAppBanner extends Component {
    constructor(props) {
        super(props);
        this.state = { isOpen: true };
    }

    render() {
        const { isOpen } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <div
                style={{
                    backgroundColor: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                    padding: '0.5rem 0.75rem',
                    margin: '-28px -15px 0',
                    boxShadow:
                        '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                }}
            >
                <button
                    type="button"
                    style={{
                        padding: '0 0.5rem',
                        borderRadius: '50%',
                        backgroundColor: '#eee',
                        border: 'none',
                    }}
                    onClick={() => this.setState({ isOpen: !isOpen })}
                >
                    <i
                        className="fa fa-close"
                        style={{ color: '#0c5c09', opacity: 0.6 }}
                    />
                </button>
                <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                    <img
                        src={logo}
                        alt="Tamilmala"
                        style={{
                            height: 50,
                            width: 50,
                            borderRadius: 10,
                            marginRight: 5,
                            marginLeft: 5,
                        }}
                    />
                    <div>
                        <h2
                            style={{
                                margin: 0,
                                color: '#0c5c09',
                                fontWeight: 'bold',
                            }}
                        >
                            Tamilmala
                        </h2>
                        <h5 style={{ margin: 0, color: '#17990f95' }}>
                            We are on Android
                        </h5>
                    </div>
                </div>
                <a href="http://play.google.com/store/apps/details?id=com.tamilmala_mobile">
                    <img
                        src={playstore}
                        alt="Install"
                        style={{ width: '100px' }}
                    />
                </a>
            </div>
        );
    }
}

export default connect(mapStateToProps)(NavContainer);
