import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';

import {
    getProfileList, viewMoreProfileList, addShortlist, removeShortlist,
} from '../actions/ProfileActions';

import Spinner from '../components/Spinner';
import ProfileList from '../components/ProfileList';
import ProfileListFilter from '../components/ProfileListFilter';

// import '../assets/css/common.css';
import '../assets/css/list.css';

class ProfileListContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFilterOpen: false,
        };
    }

    componentWillMount() {
        const { load, profileListFilter: f, profileListPage: p } = this.props;
        load(f, p);
    }

    componentWillReceiveProps(nextProps) {
        const { profileListFilter: f1, profileListPage: p1, isFetching1 } = nextProps;
        const {
            load, profileListFilter: f2, profileListPage: p2, isFetching2,
        } = this.props;
        if (!isEqual(f1.sort(), f2.sort()) || p1 !== p2 || isFetching1 !== isFetching2) {
            load(f1, p1);
        }
    }

    moveNextPage = (e) => {
        const { viewMore } = this.props;
        e.preventDefault();
        viewMore();
    };

    toggleMobileFilter = (e) => {
        e.preventDefault();
        this.setState({
            isFilterOpen: !this.state.isFilterOpen,
        });
    };

    onShortlistProfile = (profileId, remove = false) => {
        const { shortlist, removeShortlist } = this.props;
        if (remove) {
            return removeShortlist(profileId);
        }
        return shortlist(profileId);
    };

    render() {
        const {
            profileList: l, profileListFilter: f, isFetching, isMobile,
        } = this.props;
        const { isFilterOpen } = this.state;
        return (
            <div className={isFilterOpen ? 'row filter-open' : 'row'}>
                <div className="col-md-3 col-sm-4">
                    <ProfileListFilter data={f} />
                </div>
                <div className="col-md-9 col-sm-8">
                    <div className="search-results">
                        {l.photo_nudge === true ? (
                            <div className="alert alert-warning">
                                <p>You won't be able to see photo from other profiles unless you upload your photo.</p>
                            </div>
                        ) : (
                            ''
                        )}
                        <h3>Search Results ({l.count})</h3>
                        {!isFetching && l.rows.length === 0 ? (
                            <p>Empty</p>
                        ) : (
                            <ProfileList data={l.rows} isMobile={isMobile} handleShortlist={this.onShortlistProfile} />
                        )}
                        {isFetching && <Spinner />}
                        {!isFetching && (
                            <nav>
                                <ul className="pager">
                                    <li>
                                        <a href="/" onClick={this.moveNextPage}>
                                            View more
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        )}
                    </div>
                </div>
                {isMobile && (
                    <div className="pos-fixed">
                        <a onClick={this.toggleMobileFilter} href="/">
                            Filter
                        </a>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    profileList: state.profile.profileList,
    profileListPage: state.profile.profileListPage,
    profileListFilter: state.profile.profileListFilter,
    authJWT: state.authed.authJWT,
    isFetching: state.profile.isFetching,
    isMobile: state.environment.isMobile,
});

const mapDispatchToProps = dispatch => ({
    load: (f, p) => dispatch(getProfileList(f, p)),
    viewMore: () => dispatch(viewMoreProfileList()),
    shortlist: id => dispatch(addShortlist(id)),
    removeShortlist: id => dispatch(removeShortlist(id)),
});

ProfileListContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProfileListContainer);
export default ProfileListContainer;
