import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from '../utils/Link';

const propTypes = {
    authed: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
};

class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = { isOpen: false };
    }

    onNavClick = () => {
        this.setState({ isOpen: false });
    };

    render() {
        const { authed, from = 'default' } = this.props;
        const myProfileLabel =
            authed.authJWT &&
            authed.authJWT.mid &&
            authed.authJWT.mid &&
            authed.authJWT.mid.length > 0
                ? `My Profile (${authed.authJWT.mid})`
                : 'My Profile';
        return (
            <nav
                id="navigation"
                className={classNames(
                    'navbar navbar-default navbar-fixed-top affix-top',
                    { 'home-nav': from === 'home' },
                )}
            >
                <div className="container-fluid container-wrapper">
                    <div className="navbar-header">
                        <button
                            type="button"
                            className="navbar-toggle"
                            onClick={(e) => {
                                this.setState({ isOpen: !this.state.isOpen });
                            }}
                        >
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                        </button>
                        <Link className="navbar-brand" to="/">
                            Tamilmala Matrimony
                        </Link>
                    </div>
                    {(this.state.isOpen || !this.props.isMobile) && (
                        <div
                            className="navbar-collapse"
                            id="collapse-navigation-links"
                            style={{
                                background: this.props.isMobile
                                    ? '#0c5c09'
                                    : 'transparent',
                            }}
                        >
                            <ul
                                className="nav navbar-nav"
                                onClick={this.onNavClick}
                            >
                                <li>
                                    <Link to="/profile/edit/basic">
                                        {myProfileLabel}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/profile/search">
                                        Search Profile
                                    </Link>
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://api.whatsapp.com/send?phone=919600029979&amp;text=Hi%20Tamilmala%2C%20Need%20some%20support."
                                    >
                                        Help
                                    </a>
                                </li>
                            </ul>
                            <ul
                                className="nav navbar-nav navbar-right"
                                onClick={this.onNavClick}
                            >
                                {authed.accessToken ? (
                                    <li>
                                        <Link to="/logout">Log Out</Link>
                                    </li>
                                ) : (
                                    <>
                                        <li>
                                            <Link to="/login">Login</Link>
                                        </li>
                                        <li>
                                            <Link to="/signup">Register</Link>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                    )}
                </div>
            </nav>
        );
    }
}

NavBar.propTypes = propTypes;

export default NavBar;
