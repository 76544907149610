import React from 'react';
import { Field } from 'redux-form';

const TimePicker = ({ id, label }) => {
    const hours = [...Array(13).keys()].filter(i => i > 0).map(i => `00${i}`.slice(-2));
    const minutes = [...Array(60).keys()].map(i => `00${i}`.slice(-2));

    const hRows = [];
    hRows.push(
        <option key="-1" value="">
            Hour
        </option>,
    );
    hours.forEach((hr, i) => {
        hRows.push(
            <option key={i} value={hr}>
                {hr}
            </option>,
        );
    });

    const mRows = [];
    mRows.push(
        <option key="-1" value="">
            Minute
        </option>,
    );
    minutes.forEach((mn, i) => {
        mRows.push(
            <option key={i} value={mn}>
                {mn}
            </option>,
        );
    });

    return (
        <div className="form-group">
            <label>{label}</label>
            <div className="form-inline">
                <div className="form-group">
                    <Field name={`${id}_hr`} className="form-control" component="select">
                        {hRows}
                    </Field>
                </div>
                <div className="form-group">
                    <Field name={`${id}_min`} className="form-control" component="select">
                        {mRows}
                    </Field>
                </div>
                <div className="form-group">
                    <Field name={`${id}_ampm`} className="form-control" component="select">
                        <option value="">AM/PM</option>
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                    </Field>
                </div>
            </div>
        </div>
    );
};

export default TimePicker;
