import { EventTypes } from 'redux-segment';
import * as types from '../constants/ActionTypes';
import * as fetchUtils from '../utils/FetchUtils';
import { refreshToken } from './AuthedActions';

export function registerBasic(formData) {
    return (dispatch, getState) => {
        const { authed } = getState();
        return fetch(
            `${process.env.REACT_APP_API_URL}/api/profile/${
                authed.user.user_id
            }/basic`,
            fetchUtils.setPostHeaders(mapBasic(formData)),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((d) => {
                dispatch(setProfile(d.profile_id));
            })
            .then(() => {
                dispatch(registration('basic', false));
            })
            .then(() => {
                dispatch(refreshToken());
            })
            .catch((ex) => {
                console.log('parsing failed', ex);
            });
    };
}

function mapBasic(formData) {
    return {
        name: formData.name,
        surname: formData.surname,
        community: formData.community,
        gothram: formData.gothram,
        language: formData.language,
        gender: formData.gender,
        date_of_birth: [
            formData.dob_year,
            formData.dob_month,
            formData.dob_date,
        ].join('-'),
        mobiles: [formData.mobile, formData.mobileAlternate],
        email: formData.email,
        address: formData.addressHome,
        city: formData.city,
        pincode: formData.pincode,
        state: formData.state,
        country: formData.country,
    };
}

function unMapBasic(data) {
    const {
        name,
        surname,
        community,
        gothram,
        language,
        gender,
        email,
        address: addressHome,
        city,
        pincode,
        state,
        country,
        mid,
    } = data;
    const formData = {
        name,
        surname,
        community,
        gothram,
        gender,
        email,
        addressHome,
        city,
        pincode,
        state,
        country,
        mid,
        language,
    };
    const [year, month, date] = data.date_of_birth.split('-');
    formData.mobile = data.mobiles.length > 0 ? data.mobiles[0] : '';
    formData.mobileAlternate = data.mobiles.length > 1 ? data.mobiles[1] : '';
    formData.dob_year = year;
    formData.dob_month = month;
    formData.dob_date = date;
    return formData;
}

export function getBasicProfile() {
    return (dispatch, getState) => {
        const { authed } = getState();
        return fetch(
            `${process.env.REACT_APP_API_URL}/api/profile/${
                authed.user.user_id
            }/basic?t=${new Date().getTime()}`,
            fetchUtils.setGetHeaders(),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((d) => {
                dispatch(basicProfile(unMapBasic(d)));
                return d;
            })
            .catch((ex) => {
                console.log('parsing failed', ex);
            });
    };
}

export function registerPersonal(formData) {
    return (dispatch, getState) => {
        const { authed } = getState();
        return fetch(
            `${process.env.REACT_APP_API_URL}/api/profile/${
                authed.user.user_id
            }/personal`,
            fetchUtils.setPostHeaders(mapPersonal(formData)),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then(() => {
                dispatch(registration('personal', false));
            })
            .catch((ex) => {
                console.log('parsing failed', ex);
            });
    };
}

function mapPersonal(formData) {
    const {
        height,
        weight,
        complexion,
        bodyType: body_type,
        physicalStatus: physical_status,
        maritalStatus: marital_status,
        foodHabits: food_habits,
        about,
        expectation,
    } = formData;
    return {
        height: parseInt(height, 10),
        weight: parseInt(weight, 10),
        complexion,
        body_type,
        physical_status,
        marital_status,
        food_habits,
        about,
        expectation,
    };
}

function unmapPersonal(data) {
    const {
        height,
        weight,
        complexion,
        body_type: bodyType,
        physical_status: physicalStatus,
        marital_status: maritalStatus,
        food_habits: foodHabits,
        about,
    } = data;
    return {
        height: height.toString(),
        weight: weight.toString(),
        complexion,
        bodyType,
        physicalStatus,
        maritalStatus,
        foodHabits,
        about,
    };
}

export function getPersonalProfile() {
    return (dispatch, getState) => {
        const { authed } = getState();
        return fetch(
            `${process.env.REACT_APP_API_URL}/api/profile/${
                authed.user.user_id
            }/personal?t=${new Date().getTime()}`,
            fetchUtils.setGetHeaders(),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((d) => {
                dispatch(personalProfile(unmapPersonal(d)));
            })
            .catch((ex) => {
                console.log('parsing failed', ex);
            });
    };
}

export function registerWork(formData) {
    return (dispatch, getState) => {
        const { authed } = getState();
        return fetch(
            `${process.env.REACT_APP_API_URL}/api/profile/${
                authed.user.user_id
            }/work`,
            fetchUtils.setPostHeaders(mapWork(formData)),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then(() => {
                dispatch(registration('work', false));
            })
            .catch((ex) => {
                console.log('parsing failed', ex);
            });
    };
}

function mapWork(formData) {
    return {
        education: formData.education,
        education_desc: formData.educationDesc,
        profession: formData.profession,
        annual_income: formData.annualIncome,
        position: formData.position,
        work_location: formData.workLocation,
    };
}

function unmapWork(data) {
    const {
        education,
        education_desc: educationDesc,
        profession,
        annual_income: annualIncome,
        position,
        work_location: workLocation,
    } = data;
    return {
        education,
        educationDesc,
        profession,
        annualIncome,
        position,
        workLocation,
    };
}

export function getWorkProfile() {
    return (dispatch, getState) => {
        const { authed } = getState();
        return fetch(
            `${process.env.REACT_APP_API_URL}/api/profile/${
                authed.user.user_id
            }/work?t=${new Date().getTime()}`,
            fetchUtils.setGetHeaders(),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((d) => {
                dispatch(workProfile(unmapWork(d)));
            })
            .catch((ex) => {
                console.log('parsing failed', ex);
            });
    };
}

export function registerFamily(formData) {
    return (dispatch, getState) => {
        const { authed } = getState();
        return fetch(
            `${process.env.REACT_APP_API_URL}/api/profile/${
                authed.user.user_id
            }/family`,
            fetchUtils.setPostHeaders(mapFamilyFormData(formData)),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then(() => {
                dispatch(registration('family', true));
            })
            .catch((ex) => {
                console.log('parsing failed', ex);
            });
    };
}

function mapFamilyFormData(formData) {
    const {
        fatherName: father_name,
        fatherAlive: father_alive,
        motherName: mother_name,
        motherAlive: mother_alive,
        fatherOccupation: father_occupation,
        motherOccupation: mother_occupation,
        sisters,
        sistersMarried: sisters_married,
        brothers,
        brothersMarried: brothers_married,
        familyStatus: family_status,
        propertyDetails: property_details,
        aboutFamily: about_family,
    } = formData;
    return {
        father_name,
        father_alive: father_alive === 'true',
        mother_name,
        mother_alive: mother_alive === 'true',
        father_occupation,
        mother_occupation,
        sisters: parseInt(sisters, 10),
        sisters_married: parseInt(sisters_married, 10),
        brothers: parseInt(brothers, 10),
        brothers_married: parseInt(brothers_married, 10),
        family_status,
        property_details,
        about_family,
    };
}

function unmapFamily(data) {
    const {
        father_name: fatherName,
        father_alive: fatherAlive,
        mother_name: motherName,
        mother_alive: motherAlive,
        father_occupation: fatherOccupation,
        mother_occupation: motherOccupation,
        sisters,
        sisters_married: sistersMarried,
        brothers,
        brothers_married: brothersMarried,
        family_status: familyStatus,
        property_details: propertyDetails,
    } = data;
    return {
        fatherName,
        fatherAlive: fatherAlive.toString(),
        motherName,
        motherAlive: motherAlive.toString(),
        fatherOccupation,
        motherOccupation,
        sisters: sisters.toString(),
        sistersMarried: sistersMarried.toString(),
        brothers: brothers.toString(),
        brothersMarried: brothersMarried.toString(),
        familyStatus,
        propertyDetails,
    };
}

export function getFamilyProfile() {
    return (dispatch, getState) => {
        const { authed } = getState();
        return fetch(
            `${process.env.REACT_APP_API_URL}/api/profile/${
                authed.user.user_id
            }/family?t=${new Date().getTime()}`,
            fetchUtils.setGetHeaders(),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((d) => {
                dispatch(familyProfile(unmapFamily(d)));
            })
            .catch((ex) => {
                console.log('parsing failed', ex);
            });
    };
}

export function registerHoroscope(formData) {
    return (dispatch, getState) => {
        const { authed } = getState();
        return fetch(
            `${process.env.REACT_APP_API_URL}/api/profile/${
                authed.user.user_id
            }/horoscope`,
            fetchUtils.setPostHeaders(mapHoro(formData)),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then(() => {
                dispatch(registration('horoscope', true));
            })
            .catch((ex) => {
                console.log('parsing failed', ex);
            });
    };
}

function mapHoro(formData) {
    return {
        birth_time: `${formData.tob_hr}:${formData.tob_min} ${
            formData.tob_ampm
        }`,
        birth_place: formData.placeBirth,
        birth_star: formData.birthStar,
        padham: parseInt(formData.birthStarPadham, 10),
        raasi: formData.raasi,
        dhosam: formData.dhosam,
    };
}

function unmapHoro(data) {
    const {
        birth_place: placeBirth,
        birth_star: birthStar,
        padham: birthStarPadham,
        raasi,
        dhosam,
    } = data;
    const formData = {
        placeBirth,
        birthStar,
        birthStarPadham: birthStarPadham.toString(),
        raasi,
        dhosam,
    };
    if (data.birth_time) {
        formData.tob_hr = `00${
            data.birth_time.split(' ')[0].split(':')[0]
        }`.slice(-2);
        formData.tob_min = `00${
            data.birth_time.split(' ')[0].split(':')[1]
        }`.slice(-2);
        // eslint-disable-next-line prefer-destructuring
        formData.tob_ampm = data.birth_time.split(' ')[1];
    }
    return formData;
}

export function getHoroProfile() {
    return (dispatch, getState) => {
        const { authed } = getState();
        return fetch(
            `${process.env.REACT_APP_API_URL}/api/profile/${
                authed.user.user_id
            }/horoscope?t=${new Date().getTime()}`,
            fetchUtils.setGetHeaders(),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((d) => {
                dispatch(horoProfile(unmapHoro(d)));
            })
            .catch((ex) => {
                console.log('parsing failed', ex);
            });
    };
}

export function getProfilePhoto() {
    return (dispatch, getState) => {
        const { authed } = getState();
        return fetch(
            `${process.env.REACT_APP_API_URL}/api/profile/${
                authed.user.user_id
            }/photo?t=${new Date().getTime()}`,
            fetchUtils.setGetHeaders(),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((d) => {
                dispatch(photoProfile(d));
            })
            .catch((ex) => {
                console.log('parsing failed', ex);
            });
    };
}

export function removeProfilePhoto(p) {
    return (dispatch, getState) => {
        const { authed } = getState();
        return fetch(
            `${process.env.REACT_APP_API_URL}/api/profile/${
                authed.user.user_id
            }/photo?t=${new Date().getTime()}`,
            fetchUtils.setPostHeaders(p, true, 'DELETE'),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((d) => {
                dispatch(photoProfile(d));
            })
            .catch((ex) => {
                console.log('parsing failed', ex);
            });
    };
}

export function uploadPhoto(url, data) {
    return () => {
        const body = new FormData();
        Object.keys(data).forEach((k) => {
            body.append('photo', data[k]);
        });
        return fetch(url, fetchUtils.setMultiPartPost(body))
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .catch((ex) => {
                console.log('parsing failed', ex);
            });
    };
}

export function uploadPhotoComplete() {
    return (dispatch) => {
        dispatch(registration('photo', true));
        dispatch(refreshToken());
    };
}

export function uploadProfilePhoto(data) {
    console.log(data);
    return (dispatch, getState) => {
        const { authed } = getState();

        const body = new FormData();
        Object.keys(data).forEach((key) => {
            Object.keys(data[key]).forEach((k) => {
                body.append(key, data[key][k]);
            });
        });

        return fetch(
            `${process.env.REACT_APP_API_URL}/api/profile/${
                authed.user.user_id
            }/photo`,
            fetchUtils.setMultiPartPost(body),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then(() => {
                dispatch(registration('family', true));
            })
            .catch((ex) => {
                console.log('parsing failed', ex);
            });
    };
}

export function getProfileHoro() {
    return (dispatch, getState) => {
        const { authed } = getState();
        return fetch(
            `${process.env.REACT_APP_API_URL}/api/profile/${
                authed.user.user_id
            }/attachment?t=${new Date().getTime()}`,
            fetchUtils.setGetHeaders(),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((d) => {
                dispatch(horoAttach(d));
            })
            .catch((ex) => {
                console.log('parsing failed', ex);
            });
    };
}

export function uploadHoroscope(data) {
    return (dispatch, getState) => {
        const { authed } = getState();

        const body = new FormData();
        Object.keys(data).forEach((key) => {
            Object.keys(data[key]).forEach((k) => {
                body.append(key, data[key][k]);
            });
        });

        return fetch(
            `${process.env.REACT_APP_API_URL}/api/profile/${
                authed.user.user_id
            }/attachment`,
            fetchUtils.setMultiPartPost(body),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((d) => {
                dispatch(horoAttach(d));
            })
            .catch((ex) => {
                console.log('parsing failed', ex);
            });
    };
}

export function registration(s, c) {
    return {
        type: types.REGISTRATION_STATUS,
        step: s,
        isComplete: c,
    };
}

export function setProfile(pId) {
    return {
        type: types.REGISTRATION_PROFILE_ID,
        profileId: pId,
        meta: {
            analytics: {
                eventType: EventTypes.track,
                eventPayload: { profileId: pId },
            },
        },
    };
}

export function basicProfile(data) {
    return {
        type: types.GET_BASIC_PROFILE,
        basic: data,
    };
}

export function personalProfile(data) {
    return {
        type: types.GET_PERSONAL_PROFILE,
        personal: data,
    };
}

export function workProfile(data) {
    return {
        type: types.GET_WORK_PROFILE,
        work: data,
    };
}

export function familyProfile(data) {
    return {
        type: types.GET_FAMILY_PROFILE,
        family: data,
    };
}

export function horoProfile(data) {
    return {
        type: types.GET_HORO_PROFILE,
        horo: data,
    };
}

export function horoAttach(data) {
    return {
        type: types.UPLOAD_HORO_ATTACH,
        horoAttach: data,
    };
}

export function photoProfile(data) {
    return {
        type: types.GET_PHOTO_PROFILE,
        photo: data,
    };
}
