import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Checkbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: !!props.isChecked,
        };
    }

    handleChange = (e) => {
        e.stopPropagation();
        const { isChecked } = this.state;
        const {
            handleCheckboxChange,
            value,
            name,
            isGroup,
            disabled = false,
        } = this.props;
        if (disabled) {
            return;
        }
        this.setState({
            isChecked: !isChecked,
        });
        const isG = isGroup === undefined ? true : isGroup;
        handleCheckboxChange(name, value, !isChecked, isG);
    };

    render() {
        const { label, name, value, style, disabled = false } = this.props;
        const { isChecked } = this.state;

        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
                role="presentation"
                className="checkbox"
                style={style || { marginTop: 5, marginBottom: 5 }}
                onClick={this.handleChange}
            >
                <input
                    type="checkbox"
                    value={value}
                    name={name}
                    checked={!!isChecked}
                    onChange={this.handleChange}
                    disabled={disabled}
                />
                <label htmlFor={name}>{label}</label>
            </div>
        );
    }
}

Checkbox.propTypes = {
    label: PropTypes.string.isRequired,
    handleCheckboxChange: PropTypes.func.isRequired,
};

export default Checkbox;
