import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { reducer as formReducer } from 'redux-form';

import authed from './authed';
import environment from './environment';
import registration from './registration';
import profile from './profile';
import admin from './admin';
import * as types from '../constants/ActionTypes';

const appReducer = combineReducers({
    authed,
    environment,
    registration,
    profile,
    admin,
    form: formReducer,
    toastr: toastrReducer,
});

const rootReducer = (state, action) => {
    if (action.type === types.RESET_AUTHED) {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
