import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../utils/ReduxFormUtils';
import DateOfBirth from './DateOfBirth';

export const validate = (values) => {
    const errors = {};
    if (!values.mobileNo) {
        errors.mobileNo = 'Required';
    }
    if (!values.dob_year) {
        errors.dob_year = 'Required';
    }
    if (!values.dob_month) {
        errors.dob_month = 'Required';
    }
    if (!values.dob_date) {
        errors.dob_date = 'Required';
    }
    return errors;
};

class ResetPassword extends PureComponent {
    render() {
        const { handleSubmit, submitting } = this.props;
        return (
            <div>
                <div className="container main heading text-center">
                    <h1>Reset password</h1>
                    <p>Forgot your password?</p>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-6 col-xs-12 col-md-offset-4 col-sm-offset-3">
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <Field
                                    name="mobileNo"
                                    type="tel"
                                    label="Mobile number"
                                    className="form-control"
                                    component={renderField}
                                    placeholder="Mobile number"
                                />
                                <DateOfBirth label="Date of Birth" id="dob" />
                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="btn btn-lg btn-wide btn-success"
                                        disabled={submitting}
                                    >
                                        {submitting ? 'Loading...' : 'Reset'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// Decorate the form component
ResetPassword = reduxForm({
    form: 'resetPassword',
    validate,
})(ResetPassword);

export default ResetPassword;
