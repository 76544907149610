import React, { Component } from 'react';
import { connect } from 'react-redux';

import PhotoUpload from './PhotoUpload';

import {
    getProfilePhoto,
    removeProfilePhoto,
} from '../actions/RegisterActions';

import { CDN_URL } from '../constants/Constants';

class PhotoForm extends Component {
    componentWillMount() {
        const { load } = this.props;
        load();
    }

    onPhotoUpload = () => {
        const { load } = this.props;
        load();
    };

    onRemovePhoto = (p) => {
        /* if (!confirm("Do you want to remove this photo for sure!")) {
      return;
    } */
        const { removePhoto } = this.props;
        removePhoto(p);
    };

    moveBack = () => {
        const { match, moveBack } = this.props;
        const { params } = match;
        moveBack({ currentPage: 'photo', mode: params.mode });
    };

    render() {
        const { handleUpload, initialPhotos, onUploadComplete } = this.props;

        const photos = [];
        if (initialPhotos) {
            initialPhotos.forEach((p, i) => {
                photos.push(
                    <li key={i} className="col-md-3 thumb-img">
                        <div className="thumb">
                            <button
                                type="button"
                                onClick={() => {
                                    this.onRemovePhoto(p);
                                }}
                                className="close"
                            >
                                x
                            </button>
                            <img key={i} src={`${CDN_URL}${p.url}`} alt="..." />
                        </div>
                    </li>,
                );
            });
        }

        return (
            <div className="row">
                <div className="heading text-center">
                    <h3>Step 4/6: Add your photos</h3>
                </div>
                <div className="col-md-12 col-sm-12">
                    {photos.length === 0 ? (
                        <div className="alert alert-warning">
                            <p>
                                You won't be able to see photo from other
                                profiles unless you upload your photo.
                            </p>
                        </div>
                    ) : (
                        ''
                    )}
                    <div className="clearfix">
                        <PhotoUpload
                            name="photoUpload"
                            handleUpload={handleUpload}
                            onPhotoUpload={this.onPhotoUpload}
                        />
                    </div>
                    <ul className="row list-unstyled">{photos}</ul>
                </div>
                <div className="onboard-next-container col-xs-12 text-right">
                    <button
                        onClick={onUploadComplete}
                        className="btn btn-default onboard-back-button pull-right"
                    >
                        Next
                    </button>
                    <button
                        className="btn btn-default onboard-back-button pull-left"
                        onClick={this.moveBack}
                    >
                        Back
                    </button>
                </div>
            </div>
        );
    }
}

const reduxConnector = connect(
    (state) => ({
        initialPhotos: state.registration.photo,
    }),
    { load: getProfilePhoto, removePhoto: removeProfilePhoto },
);

export default reduxConnector(PhotoForm);
