import React, { Component } from 'react';
import { connect } from 'react-redux';

import '../assets/css/App.css';
import '../assets/css/components/spinner.css';
import '../assets/css/components/subscription-summary.css';
import '../assets/css/toastr.css';
import '../assets/css/common.css';

import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import { initEnvironment } from '../actions/EnvironmentActions';

import {
    initAuth,
    loginUser,
    signupUser,
    changePassword,
    resetPassword,
    refreshToken,
} from '../actions/AuthedActions';

import Login from '../components/Login';
import Logout from '../components/Logout';
import Signup from '../components/Signup';
import Spinner from '../components/Spinner';
import Forbidden from '../components/Forbidden';
import Upgrade from '../components/Upgrade';
import RegisterUpgrade from '../components/RegisterUpgrade';

import Footer from '../components/Footer';
import Home from '../components/Home';
import ChangePassword from '../components/ChangePassword';
import ResetPassword from '../components/ResetPassword';

import ProfileContainer from './ProfileContainer';
import ProfileListContainer from './ProfileListContainer';
import RegisterContainer from './RegisterContainer';
import NavContainer, { MobileAppBanner } from './NavContainer';
import SubscriptionContainer from './SubscriptionContainer';
import AdminProfileListContainer from './AdminProfileListContainer';
import MarriagesContainer from './MarriagesContainer';

import VerifyEmail from '../components/VerifyEmail';

const DefaultLayout = ({ component: Component, ...rest }) => (
    <div id="wrap">
        <NavContainer showNavLinks />
        <div className="container">
            {rest.isMobile ? <MobileAppBanner /> : null}
            <Component {...rest} />
        </div>
        <Footer />
    </div>
);

const PrivateRoute = ({
    component: Component,
    authed,
    adminOnly = false,
    ...rest
}) => (
    <Route
        {...rest}
        render={(props) => {
            if (!authed.accessToken) {
                return (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                    />
                );
            }

            if (adminOnly && authed.authJWT.role !== 'ADMIN') {
                return <Redirect to="/profile/search" />;
            }

            return <Route {...rest} component={Component} />;
        }}
    />
);

const PublicRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} component={Component} />
);

class App extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(initEnvironment());
        dispatch(initAuth());
        dispatch(refreshToken());
    }

    handleLogin = (values, nextUrl) => {
        const { dispatch } = this.props;
        return dispatch(loginUser(values, nextUrl));
    };

    handleSignup = (values) => {
        const { domain = 'tamilmala', ...otherValues } = values;
        const { dispatch } = this.props;
        return dispatch(signupUser({ domain, ...otherValues }));
    };

    handleChangePassword = (values) => {
        const { dispatch } = this.props;
        return dispatch(changePassword(values));
    };

    handleResetPassword = (values) => {
        const { dispatch } = this.props;
        return dispatch(resetPassword(values));
    };

    render() {
        const { isAuthChecked, authed, isMobile } = this.props;

        if (!isAuthChecked) {
            return <Spinner />;
        }

        return (
            <Switch>
                <PublicRoute
                    authed={authed}
                    path="/"
                    exact
                    render={(props) => <Home {...props} />}
                />
                <PublicRoute
                    authed={authed}
                    path="/login"
                    render={(props) => (
                        <DefaultLayout
                            isMobile={isMobile}
                            component={Login}
                            {...props}
                            onSubmit={this.handleLogin}
                        />
                    )}
                />
                <PublicRoute
                    authed={authed}
                    path={['/signup/:domain', '/signup']}
                    render={(props) => (
                        <DefaultLayout
                            isMobile={isMobile}
                            component={Signup}
                            {...props}
                            onSubmit={this.handleSignup}
                        />
                    )}
                />
                <PublicRoute
                    authed={authed}
                    path="/verify/email/:userId/:secret"
                    render={(props) => (
                        <DefaultLayout
                            isMobile={isMobile}
                            component={VerifyEmail}
                            {...props}
                            onSubmit={this.handleSignup}
                        />
                    )}
                />
                <PrivateRoute
                    authed={authed}
                    path="/password/change"
                    render={(props) => (
                        <DefaultLayout
                            isMobile={isMobile}
                            component={ChangePassword}
                            {...props}
                            onSubmit={this.handleChangePassword}
                        />
                    )}
                />
                <PublicRoute
                    authed={authed}
                    path="/password/reset"
                    render={(props) => (
                        <DefaultLayout
                            isMobile={isMobile}
                            component={ResetPassword}
                            {...props}
                            onSubmit={this.handleResetPassword}
                        />
                    )}
                />
                <PrivateRoute
                    authed={authed}
                    path="/logout"
                    component={Logout}
                />
                <PrivateRoute
                    authed={authed}
                    path="/forbidden"
                    render={(props) => (
                        <DefaultLayout
                            isMobile={isMobile}
                            component={Forbidden}
                            {...props}
                        />
                    )}
                />
                <PrivateRoute
                    authed={authed}
                    path="/register-upgrade"
                    render={(props) => (
                        <DefaultLayout
                            isMobile={isMobile}
                            component={RegisterUpgrade}
                            {...props}
                        />
                    )}
                />

                <PrivateRoute
                    authed={authed}
                    path="/upgrade"
                    render={(props) => (
                        <DefaultLayout
                            isMobile={isMobile}
                            component={Upgrade}
                            {...props}
                        />
                    )}
                />

                <PrivateRoute
                    authed={authed}
                    path="/profile/search"
                    render={(props) => (
                        <DefaultLayout
                            isMobile={isMobile}
                            component={ProfileListContainer}
                            {...props}
                        />
                    )}
                />
                <PrivateRoute
                    authed={authed}
                    path="/profile/view/:profileId"
                    render={(props) => (
                        <DefaultLayout
                            isMobile={isMobile}
                            component={ProfileContainer}
                            {...props}
                        />
                    )}
                />
                <PrivateRoute
                    authed={authed}
                    path="/profile/:mode"
                    render={(props) => (
                        <DefaultLayout
                            isMobile={isMobile}
                            component={RegisterContainer}
                            {...props}
                        />
                    )}
                />

                <PrivateRoute
                    authed={authed}
                    path="/subscription"
                    render={(props) => (
                        <DefaultLayout
                            isMobile={isMobile}
                            component={SubscriptionContainer}
                            {...props}
                        />
                    )}
                />

                <PrivateRoute
                    authed={authed}
                    adminOnly={true}
                    path="/admin/profile/search"
                    render={(props) => (
                        <DefaultLayout
                            isMobile={isMobile}
                            component={AdminProfileListContainer}
                            {...props}
                        />
                    )}
                />

                <PrivateRoute
                    authed={authed}
                    adminOnly={true}
                    path="/admin/marriages"
                    render={(props) => (
                        <DefaultLayout
                            isMobile={isMobile}
                            component={MarriagesContainer}
                            {...props}
                        />
                    )}
                />

                <Route render={() => <h3>No Match</h3>} />
            </Switch>
        );
    }
}

function mapStateToProps(state) {
    const { authed, environment, registration } = state;
    const { isAuthChecked } = authed;
    const { height, isMobile, width } = environment;
    const {
        registrationStep,
        isRegistrationComplete,
        profileId,
    } = registration;

    return {
        height,
        isMobile,
        registrationStep,
        isRegistrationComplete,
        profileId,
        isAuthChecked,
        authed,
        width,
    };
}

export default withRouter(connect(mapStateToProps)(App));
