import { toastr } from 'react-redux-toastr';
import * as types from '../constants/ActionTypes';
import * as fetchUtils from '../utils/FetchUtils';

import { push } from '../utils/history';

export function getProfileList(filter, page = 1) {
    return (dispatch, getState) => {
        dispatch(fetchingData());
        return fetch(
            `${process.env.REACT_APP_API_URL}/api/admin/profile/list/${page}`,
            fetchUtils.setPostHeaders(mapFilter(filter)),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((d) => {
                dispatch(profileList(d, page));
            })
            .catch((ex) => {
                dispatch(fetchingData(false));
                if (ex.response) {
                    if (ex.response.status === 403) {
                        return push('/forbidden');
                    }
                }
                fetchUtils.handleError('Something went wrong...', ex);
            });
    };
}

export function updateProfileStatus(profileId, status) {
    return (dispatch, getState) => {
        const updateStatusReq = { profile_id: profileId, status };
        return fetch(
            `${
                process.env.REACT_APP_API_URL
            }/api/admin/profile/${profileId}/status`,
            fetchUtils.setPostHeaders(updateStatusReq),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((d) => {
                toastr.success('Success', 'Profile status updated...');
            })
            .then(() => {
                dispatch(profileStatusUpdate(profileId, status));
            })
            .catch((ex) => {
                console.log('parsing failed', ex);
            });
    };
}

export function uploadMarriagePhoto(image, caption) {
    return () => {
        const body = new FormData();
        body.append('photo', image);
        body.append('caption', caption);
        return fetch(
            `${process.env.REACT_APP_API_URL}/api/admin/marriage`,
            fetchUtils.setMultiPartPost(body),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .catch((ex) => {
                console.log('parsing failed', ex);
            });
    };
}

export function updateProfileFeatured(profileId, status) {
    return (dispatch, getState) => {
        const updateStatusReq = { profile_id: profileId, status };
        return fetch(
            `${
                process.env.REACT_APP_API_URL
            }/api/admin/profile/${profileId}/featured`,
            fetchUtils.setPostHeaders(updateStatusReq),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((d) => {
                toastr.success('Success', 'Profile status updated...');
            })
            .then(() => {
                dispatch(profileFeaturedUpdate(profileId, status));
            })
            .catch((ex) => {
                console.log('parsing failed', ex);
            });
    };
}

function mapFilter(filter) {
    const mf = {};
    if (typeof filter === 'undefined') return mf;
    for (const f of filter) {
        if (mf[f.name]) {
            mf[f.name].push(f.value);
        } else {
            mf[f.name] = [f.value];
        }
    }
    return mf;
}

export function viewMoreProfileList() {
    return (dispatch) => dispatch(profileListViewMore());
}

export function resetProfileListFilter() {
    return (dispatch) =>
        dispatch({
            type: types.ADMIN_PROFILE_LIST_FILTER_RESET,
        });
}

export function updateProfileListFilter(n, v, c, m) {
    return (dispatch, getState) =>
        dispatch(
            profileListFilter({
                name: n,
                value: v,
                isChecked: c,
                isMulti: m,
            }),
        );
}

export function profileList(data, page) {
    return {
        type: types.ADMIN_PROFILE_LIST,
        data,
        page,
    };
}

export function profileListViewMore() {
    return {
        type: types.ADMIN_PROFILE_LIST_VIEW_MORE,
    };
}

export function fetchingData(status = true) {
    return {
        type: types.ADMIN_FETCHING_DATA,
        status,
    };
}

export function profileStatusUpdate(profileId, status) {
    return {
        type: types.ADMIN_PROFILE_STATUS_UPDATE,
        profileId,
        status,
    };
}

export function profileFeaturedUpdate(profileId, status) {
    return {
        type: types.ADMIN_PROFILE_FEATURED_UPDATE,
        profileId,
        status,
    };
}

export function profileListFilter(data) {
    return {
        type: types.ADMIN_PROFILE_LIST_FILTER,
        profileListFilter: data,
    };
}
