import jwtDecode from 'jwt-decode';
import * as types from '../constants/ActionTypes';

const initialState = {
    accessToken: null,
    authJWT: null,
    user: {},
    isAuthChecked: false,
    isEmailVerified: null,
};

export default function authed(state = initialState, action) {
    switch (action.type) {
    case types.RECEIVE_ACCESS_TOKEN:
        return Object.assign({}, state, {
            accessToken: action.accessToken,
            authJWT: jwtDecode(action.accessToken),
        });
    case types.RECEIVE_AUTHED_USER:
        return Object.assign({}, state, {
            user: action.user,
            isAuthChecked: true,
        });
    case types.CHECKED_AUTHED:
        return Object.assign({}, state, {
            isAuthChecked: action.isAuthChecked,
        });
    case types.VERIFY_EMAIL:
        return Object.assign({}, state, {
            isEmailVerified: action.isEmailVerified,
        });
    default:
        return state;
    }
}
