import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from '../utils/Link';
import {
    renderField,
    normalizePhone,
    renderSelectField,
} from '../utils/ReduxFormUtils';

export const validate = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = 'Required';
    }
    if (!values.mobileNo) {
        errors.mobileNo = 'Required';
    }
    if (values.mobileNo && !/^\d{10}$/.test(values.mobileNo)) {
        errors.mobileNo = 'Invalid mobile number';
    }
    if (!values.password) {
        errors.password = 'Required';
    } else if (!values.confirmPassword) {
        errors.confirmPassword = 'Required';
    } else if (values.password !== values.confirmPassword) {
        errors.password = "Password and its confirmation doesn't match";
    }
    return errors;
};

class Signup extends React.PureComponent {
    render() {
        const { handleSubmit, submitting, match } = this.props;
        const showDomain = Boolean(match.params.domain);
        return (
            <div>
                <div className="container main heading text-center">
                    <h1>Sign up to find your perfect lifepartner</h1>
                    <p>
                        Already have an account?{' '}
                        <Link to="/login">Log in here</Link>
                    </p>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-6 col-xs-12 col-md-offset-4 col-sm-offset-3">
                            <form onSubmit={handleSubmit} autoComplete="off">
                                {showDomain && (
                                    <Field
                                        name="domain"
                                        className="form-control"
                                        component={renderSelectField}
                                        label="Domain"
                                    >
                                        <option value="marathimala">
                                            Marathimala
                                        </option>
                                        <option value="tamilmala">
                                            Tamilmala
                                        </option>
                                    </Field>
                                )}
                                <Field
                                    name="name"
                                    type="text"
                                    className="form-control"
                                    component={renderField}
                                    label="Bride/Groom Name"
                                    placeholder="Enter Bride/Groom Name"
                                />
                                <Field
                                    name="mobileNo"
                                    type="text"
                                    normalize={normalizePhone}
                                    className="form-control"
                                    component={renderField}
                                    label="Contact Mobile"
                                    placeholder="Contact mobile number"
                                />
                                <Field
                                    name="password"
                                    type="password"
                                    className="form-control"
                                    component={renderField}
                                    label="Password"
                                    placeholder="Your password"
                                />
                                <Field
                                    name="confirmPassword"
                                    type="password"
                                    className="form-control"
                                    component={renderField}
                                    label="Confirm Password"
                                    placeholder="Confirm your password"
                                />
                                <button
                                    type="submit"
                                    className="btn btn-lg btn-wide btn-success"
                                    disabled={submitting}
                                >
                                    Signup
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// Decorate the form component
export default reduxForm({
    form: 'signup',
    validate,
})(Signup);
