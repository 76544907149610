import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createLogger } from 'redux-logger';
import { createTracker } from 'redux-segment';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from '../reducers';

const tracker = createTracker();

const configureStore = (initialState) => {
    const middlewares = [thunk, tracker];
    let enhancers = applyMiddleware(...middlewares);
    if (process.env.NODE_ENV === 'development') {
        const logger = createLogger();
        middlewares.push(logger);
        enhancers = composeWithDevTools(enhancers);
    }
    return createStore(rootReducer, initialState, enhancers);
};

export default configureStore;
