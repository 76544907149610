import { EventTypes } from 'redux-segment';
import * as types from '../constants/ActionTypes';
import * as fetchUtils from '../utils/FetchUtils';
import { push } from '../utils/history';

export function viewProfile(profileId) {
    return (dispatch, getState) => {
        dispatch({ type: types.RESET_PROFILE });
        dispatch(fetchingData());
        return fetch(
            `${process.env.REACT_APP_API_URL}/api/profile/view/${profileId}`,
            fetchUtils.setGetHeaders(),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((d) => {
                dispatch(profileView(d));
            })
            .catch((ex) => {
                if (ex.response) {
                    if (ex.response.status === 403) {
                        return push('/register-upgrade');
                    }
                }
                fetchUtils.handleError('Something went wrong...', ex);
            });
    };
}

export function downloadProfile(profileId) {
    return (dispatch, getState) => {
        dispatch(fetchingData());
        return fetch(
            `${
                process.env.REACT_APP_API_URL
            }/api/profile/download/${profileId}`,
            fetchUtils.setGetHeaders(),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((d) => {
                dispatch(profileDownload(d));
            })
            .catch((ex) => {
                if (ex.response) {
                    if (ex.response.status === 402) {
                        return push('/upgrade');
                    }
                    if (ex.response.status >= 400 && ex.response.status < 500) {
                        fetchUtils.handleError('Access Denied', ex);
                        return;
                    }
                }
                fetchUtils.handleError('Something went wrong...', ex);
            });
    };
}

export function getProfileList(filter, page = 1) {
    return (dispatch, getState) => {
        dispatch(fetchingData());
        return fetch(
            `${process.env.REACT_APP_API_URL}/api/profile/recent/${page}`,
            fetchUtils.setPostHeaders(mapFilter(filter)),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((d) => {
                dispatch(profileList(d, page));
            })
            .catch((ex) => {
                if (ex.response) {
                    if (ex.response.status === 403) {
                        return push('/forbidden');
                    }
                }
                fetchUtils.handleError('Something went wrong...', ex);
            });
    };
}

function mapFilter(filter) {
    const mf = {};
    if (typeof filter === 'undefined') return mf;
    for (const f of filter) {
        if (mf[f.name]) {
            mf[f.name].push(f.value);
        } else {
            mf[f.name] = [f.value];
        }
    }
    return mf;
}

export function getSubscription() {
    return (dispatch, getState) =>
        fetch(
            `${process.env.REACT_APP_API_URL}/api/subscription`,
            fetchUtils.setGetHeaders(),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((d) => {
                dispatch(retrieveSubscription(d));
            })
            .catch((ex) => {
                if (ex.response) {
                    if (ex.response.status === 403) {
                        return push('/upgrade');
                    }
                }
                fetchUtils.handleError('Something went wrong...', ex);
            });
}

export function getProfileDownloadList(page = 1) {
    return (dispatch, getState) =>
        fetch(
            `${
                process.env.REACT_APP_API_URL
            }/api/subscription/download/list/${page}`,
            fetchUtils.setGetHeaders(),
        )
            .then(fetchUtils.checkStatus)
            .then(fetchUtils.parseJSON)
            .then((d) => {
                dispatch(profileDownloadList(d, page));
            })
            .catch((ex) => {
                if (ex.response) {
                    if (ex.response.status === 403) {
                        return push('/upgrade');
                    }
                }
                fetchUtils.handleError('Something went wrong...', ex);
            });
}

export function addShortlist(profileId) {
    return (dispatch) =>
        fetchUtils
            .postJSON(
                `${process.env.REACT_APP_API_URL}/api/profile/shortlist`,
                {
                    profile_id: profileId,
                },
            )
            .then((d) =>
                dispatch({ type: types.SHORTLIST_ADD_SUCCESS, id: profileId }),
            )
            .catch((ex) => {
                fetchUtils.handleError('Error shortlisting...', ex);
            });
}

export function removeShortlist(profileId) {
    return (dispatch) =>
        fetchUtils
            .deleteJSON(
                `${process.env.REACT_APP_API_URL}/api/profile/shortlist`,
                {
                    profile_id: profileId,
                },
            )
            .then((d) =>
                dispatch({
                    type: types.SHORTLIST_REMOVE_SUCCESS,
                    id: profileId,
                }),
            )
            .catch((ex) => {
                fetchUtils.handleError('Error shortlisting...', ex);
            });
}

export function viewMoreProfileDownloadList() {
    return (dispatch) => dispatch(profileDownloadListViewMore());
}

export function viewMoreProfileList() {
    return (dispatch) => dispatch(profileListViewMore());
}

export function updateProfileListFilter(n, v, c, m) {
    return (dispatch, getState) =>
        dispatch(
            profileListFilter({
                name: n,
                value: v,
                isChecked: c,
                isMulti: m,
            }),
        );
}

export function profileList(data, page) {
    return {
        type: types.PROFILE_LIST,
        data,
        page,
    };
}

export function profileListViewMore() {
    return {
        type: types.PROFILE_LIST_VIEW_MORE,
    };
}

export function profileDownloadListViewMore() {
    return {
        type: types.PROFILE_DOWNLOAD_LIST_VIEW_MORE,
    };
}

export function profileListFilter(data) {
    return {
        type: types.PROFILE_LIST_FILTER,
        profileListFilter: data,
    };
}

export function profileView(data) {
    return {
        type: types.VIEW_PROFILE,
        data,
        meta: {
            analytics: {
                eventType: EventTypes.track,
                eventPayload: data,
            },
        },
    };
}

export function profileDownload(data) {
    return {
        type: types.DOWNLOAD_PROFILE,
        data,
        meta: {
            analytics: {
                eventType: EventTypes.track,
                eventPayload: data,
            },
        },
    };
}

export function retrieveSubscription(data) {
    return {
        type: types.GET_SUBSCRIPTION,
        data,
    };
}

export function profileDownloadList(data, page) {
    return {
        type: types.PROFILE_DOWNLOAD_LIST,
        data,
        page,
    };
}

export function fetchingData() {
    return {
        type: types.FETCHING_DATA,
    };
}
