import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

class DateOfBirth extends PureComponent {
    render() {
        const dates = [...Array(32).keys()].filter(i => i > 0);
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const startYear = new Date().getFullYear() - 18;
        const years = [...Array(50).keys()].map(i => startYear - i);

        const { id, label } = this.props;

        const dRows = [];
        dRows.push(
            <option key="-1" value="">
                Date
            </option>,
        );
        dates.forEach((dt, i) => {
            dRows.push(
                <option key={i} value={dt}>
                    {dt}
                </option>,
            );
        });

        const mRows = [];
        mRows.push(
            <option key="-1" value="">
                Month
            </option>,
        );
        months.forEach((mn, i) => {
            mRows.push(
                <option key={i + 1} value={i + 1}>
                    {mn}
                </option>,
            );
        });

        const yRows = [];
        yRows.push(
            <option key="-1" value="-1">
                Year
            </option>,
        );

        years.forEach((yr, i) => {
            yRows.push(
                <option key={i} value={yr}>
                    {yr}
                </option>,
            );
        });

        return (
            <div className="form-group">
                {label && <label>{label}</label>}
                <div className="form-inline">
                    <div className="form-group" style={{ padding: 5 }}>
                        <Field name={`${id}_month`} className="form-control" component="select">
                            {mRows}
                        </Field>
                    </div>
                    <div className="form-group" style={{ padding: 5 }}>
                        <Field name={`${id}_date`} className="form-control" component="select">
                            {dRows}
                        </Field>
                    </div>
                    <div className="form-group" style={{ padding: 5 }}>
                        <Field name={`${id}_year`} className="form-control" component="select">
                            {yRows}
                        </Field>
                    </div>
                </div>
            </div>
        );
    }
}

export default DateOfBirth;
