import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Link } from '../utils/Link';
import { push } from '../utils/history';

import BasicForm from '../components/BasicForm';
import WorkForm from '../components/WorkForm';
import FamilyForm from '../components/FamilyForm';
import HoroscopeForm from '../components/HoroscopeForm';
import PersonalForm from '../components/PersonalForm';
import PhotoForm from '../components/PhotoForm';

import { PROFILE_SECTION } from '../constants/Constants';
import { toTitleCase } from '../utils/StringUtils';

import {
    registerBasic,
    registerPersonal,
    registerHoroscope,
    registerWork,
    registerFamily,
    uploadPhoto,
    uploadPhotoComplete,
} from '../actions/RegisterActions';

class RegisterContainer extends Component {
    handleBasicSubmit = (values) => {
        const { dispatch, match } = this.props;
        const mode = match.params.mode;
        dispatch(registerBasic(values));
        return dispatch(push(`/profile/${mode}/personal`));
    };

    handlePersonalSubmit = (values) => {
        const { dispatch, match } = this.props;
        const mode = match.params.mode;
        dispatch(registerPersonal(values));
        return dispatch(push(`/profile/${mode}/work`));
    };

    handleWorkSubmit = (values) => {
        const { dispatch, match } = this.props;
        const mode = match.params.mode;
        dispatch(registerWork(values));
        return dispatch(push(`/profile/${mode}/photo`));
    };

    handleFamilySubmit = (values) => {
        const { dispatch, match } = this.props;
        const mode = match.params.mode;
        dispatch(registerFamily(values));
        return dispatch(push(`/profile/${mode}/horoscope`));
    };

    handleHoroSubmit = (values) => {
        const { dispatch, match } = this.props;
        const mode = match.params.mode;
        dispatch(registerHoroscope(values));
        if (mode === 'register') return dispatch(push('/profile/search'));
        return dispatch(push(`/profile/${mode}/basic`));
    };

    photoUploadComplete = () => {
        const { dispatch, match } = this.props;
        const mode = match.params.mode;
        dispatch(uploadPhotoComplete());
        return push(`/profile/${mode}/family`);
    };

    handlePhotoSubmitV2 = (values) => {
        const { dispatch, authed } = this.props;
        return dispatch(
            uploadPhoto(
                `${process.env.REACT_APP_API_URL}/api/profile/${
                    authed.user.user_id
                }/photo`,
                values,
            ),
        );
    };

    moveBack = ({ currentPage, mode }) => {
        console.log('moveback');
        const { dispatch } = this.props;
        let nextPageIndex = 0;
        const currentPageIndex = PROFILE_SECTION.indexOf(currentPage);
        if (currentPageIndex < PROFILE_SECTION.length)
            nextPageIndex = currentPageIndex - 1;
        dispatch(push(`/profile/${mode}/${PROFILE_SECTION[nextPageIndex]}`));
    };

    render() {
        const { match, authed } = this.props;
        const { params } = match;
        const hasSubscription =
            authed.authJWT &&
            authed.authJWT.subscription_id &&
            authed.authJWT.subscription_id.length > 0;
        return (
            <div className="row" id="candidate-onboarding">
                <div className="col-md-2">
                    {params.mode === 'edit' ? (
                        <ul className="nav nav-tabs">
                            {PROFILE_SECTION.map((p, i) => (
                                <li key={i}>
                                    <Link to={`/profile/edit/${p}`}>
                                        {toTitleCase(p)}
                                    </Link>
                                </li>
                            ))}
                            <li>
                                <Link to="/password/change">
                                    Change Password
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={
                                        hasSubscription
                                            ? '/subscription'
                                            : '/upgrade'
                                    }
                                >
                                    Subscription
                                </Link>
                            </li>
                        </ul>
                    ) : (
                        ''
                    )}
                </div>
                <div className="sheet col-md-8 col-xs-12">
                    <div className="candidate-onboard-step">
                        <Switch>
                            <Route
                                exact
                                path="/profile/:mode/basic"
                                render={(props) => (
                                    <BasicForm
                                        {...props}
                                        onSubmit={this.handleBasicSubmit}
                                        moveBack={this.moveBack}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/profile/:mode/personal"
                                render={(props) => (
                                    <PersonalForm
                                        {...props}
                                        onSubmit={this.handlePersonalSubmit}
                                        moveBack={this.moveBack}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/profile/:mode/work"
                                render={(props) => (
                                    <WorkForm
                                        {...props}
                                        onSubmit={this.handleWorkSubmit}
                                        moveBack={this.moveBack}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/profile/:mode/photo"
                                render={(props) => (
                                    <PhotoForm
                                        {...props}
                                        handleUpload={this.handlePhotoSubmitV2}
                                        onUploadComplete={
                                            this.photoUploadComplete
                                        }
                                        moveBack={this.moveBack}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/profile/:mode/family"
                                render={(props) => (
                                    <FamilyForm
                                        {...props}
                                        onSubmit={this.handleFamilySubmit}
                                        moveBack={this.moveBack}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/profile/:mode/horoscope"
                                render={(props) => (
                                    <HoroscopeForm
                                        {...props}
                                        onSubmit={this.handleHoroSubmit}
                                        moveBack={this.moveBack}
                                    />
                                )}
                            />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

RegisterContainer = connect((state) => ({
    authed: state.authed,
}))(RegisterContainer);

export default RegisterContainer;
