/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';
import filter from 'lodash/filter';
import React, { Component, Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
// import '../assets/css/common.css';
import '../assets/css/list.css';
import { CDN_URL } from '../constants/Constants';
import { Link } from '../utils/Link';

class ShortlistButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUpdating: false,
        };
    }

    onShortlist = () => {
        const { onClick } = this.props;
        this.setState({ isUpdating: true });
        onClick()
            .then(() => {
                this.setState({ isUpdating: false });
            })
            .catch(() => {})
            .then(() => {
                this.setState({ isUpdating: false });
            });
    };

    render() {
        const { hasShortlisted } = this.props;
        const { isUpdating } = this.state;
        return (
            <button
                type="button"
                disabled={isUpdating}
                onClick={this.onShortlist}
                className={
                    hasShortlisted ? 'shortlist shortlisted' : 'shortlist'
                }
            >
                {isUpdating ? (
                    'Updating...'
                ) : (
                    <div>
                        <span
                            className={classNames(
                                'glyphicon',
                                { 'glyphicon-star': hasShortlisted },
                                { glyphicon: !hasShortlisted },
                            )}
                        />
                        <span className={hasShortlisted ? 'after' : 'before'}>
                            {hasShortlisted ? 'Shortlisted' : 'Shortlist'}
                        </span>
                    </div>
                )}
            </button>
        );
    }
}

class ProfileListMView extends Component {
    render() {
        const { p, handleShortlist } = this.props;
        return (
            <li className="clearfix">
                <div className="media">
                    <div
                        className="pull-left thumb-image"
                        style={{ width: 120, height: 120 }}
                    >
                        <img
                            className="media-object"
                            src={p.photoUrl}
                            ref={(i) => (this.img = i)}
                            onError={() => {
                                this.img.onerror = null;
                                this.img.src = p.photoOnErrorUrl;
                            }}
                            alt={p.name}
                        />
                    </div>
                    <div className="media-body">
                        <h4>
                            <Link to={`/profile/view/${p.profile_id}`}>
                                {p.name}
                            </Link>
                        </h4>
                        <p style={{ marginTop: 8 }}>
                            {filter([
                                `${p.age}yrs`,
                                `${p.personal.height_ft}ft`,
                                p.surname,
                                p.community,
                                p.horoscope.birth_star,
                                p.work.education,
                                p.work.position,
                                p.city,
                            ]).join(', ')}
                        </p>
                    </div>
                </div>
                <div className="clearfix">
                    <div className="pull-left">
                        <ShortlistButton
                            onClick={() =>
                                handleShortlist(p.profile_id, p.hasShortlisted)
                            }
                            hasShortlisted={p.hasShortlisted}
                        />
                    </div>
                    <div className="pull-right">
                        <Link
                            className="pull-right"
                            to={`/profile/view/${p.profile_id}`}
                        >
                            View Details
                        </Link>
                    </div>
                </div>
            </li>
        );
    }
}

class ProfileListXView extends Component {
    render() {
        const { p, handleShortlist, userDomain } = this.props;
        const isTamilmala = userDomain === 'tamilmala';
        return (
            <li className="clearfix">
                <div className="clearfix">
                    <div className="pull-left">
                        <h4>
                            <Link to={`/profile/view/${p.profile_id}`}>
                                {p.name}
                            </Link>
                        </h4>
                    </div>
                    <div className="pull-right">
                        <ShortlistButton
                            onClick={() =>
                                handleShortlist(p.profile_id, p.hasShortlisted)
                            }
                            hasShortlisted={p.hasShortlisted}
                        />
                    </div>
                </div>
                <div className="media">
                    <div className="pull-left thumb-image">
                        <img
                            className="media-object"
                            src={`${p.photoUrl}`}
                            // eslint-disable-next-line no-return-assign
                            ref={(i) => (this.img = i)}
                            onError={() => {
                                this.img.onerror = null;
                                this.img.src = p.photoOnErrorUrl;
                            }}
                            alt={p.name}
                        />
                    </div>
                    <div className="media-body">
                        <div className="row">
                            <div className="col-md-6">
                                <ul className="list-inline">
                                    <li>
                                        <label>Age</label>
                                        <span>{p.age}yrs</span>
                                    </li>
                                    <li>
                                        <label>Height</label>
                                        <span>{p.personal.height_ft}ft</span>
                                    </li>
                                    <li>
                                        <label>{`${
                                            isTamilmala ? 'Caste' : 'Community'
                                        }`}</label>
                                        <span>{p.community}</span>
                                    </li>
                                    <li>
                                        <label>City </label>
                                        <span>{p.city}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <ul className="list-inline">
                                    <li>
                                        <label>{`${
                                            isTamilmala ? 'Subcaste' : 'Surname'
                                        } `}</label>
                                        <span>{p.surname}</span>
                                    </li>
                                    <li>
                                        <label>Birth Star </label>
                                        <span>
                                            {p.horoscope.birth_star
                                                ? p.horoscope.birth_star
                                                : ' - '}
                                        </span>
                                    </li>
                                    <li>
                                        <label>Education </label>
                                        <span>
                                            {p.work.education
                                                ? p.work.education
                                                : ' - '}
                                        </span>
                                    </li>
                                    <li>
                                        <label>Occupation </label>
                                        <span>
                                            {p.work.position
                                                ? p.work.position
                                                : ' - '}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <Link
                    className="pull-right"
                    to={`/profile/view/${p.profile_id}`}
                >
                    View Details
                </Link>
            </li>
        );
    }
}

const Promo = () => {
    return null;
    // return (
    //     <li
    //         className="clearfix"
    //         style={{
    //             backgroundColor: '#f6f7f9',
    //             textAlign: 'center',
    //             border: 'none',
    //             boxShadow: 'none',
    //         }}
    //     >
    //         <a
    //             target="_blank"
    //             rel="noopener noreferrer"
    //             href="https://www.facebook.com/devale.ajay.9"
    //         >
    //             <img
    //                 style={{ maxWidth: '100%' }}
    //                 alt="Ajay Kumar fotography promo"
    //                 src="https://marathimala.s3.amazonaws.com/assets/promo/ajay-kumar-fotography.jpg"
    //             />
    //         </a>
    //     </li>
    // );
};

class ProfileList extends PureComponent {
    render() {
        const { data, isMobile, handleShortlist, userDomain } = this.props;
        const profiles = data.map((p, i) => {
            const profile = Object.assign(
                {},
                p,
                { name: p.name.length > 0 ? `${p.name} (${p.mid})` : p.mid },
                {
                    photoUrl:
                        p.photos.length > 0
                            ? `${CDN_URL}${p.photos[0].short}`
                            : `${CDN_URL}/profile-photo-original/5ee0f71f6736ca00043db22a-${
                                  p.gender === 'Female'
                                      ? '8oDksyTcpA'
                                      : 'C3NRqNAOzP'
                              }.jpg`,
                },
                {
                    photoOnErrorUrl:
                        p.photos.length > 0
                            ? `${CDN_URL}${p.photos[0].url}`
                            : `${CDN_URL}/profile-photo-original/5ee0f71f6736ca00043db22a-${
                                  p.gender === 'Female'
                                      ? '8oDksyTcpA'
                                      : 'C3NRqNAOzP'
                              }.jpg`,
                },
            );
            return isMobile ? (
                <Fragment key={p.mid}>
                    {i === 3 ? <Promo /> : null}
                    <ProfileListMView
                        p={profile}
                        userDomain={userDomain}
                        handleShortlist={handleShortlist}
                    />
                </Fragment>
            ) : (
                <Fragment key={p.mid}>
                    {i === 3 ? <Promo /> : null}
                    <ProfileListXView
                        p={profile}
                        userDomain={userDomain}
                        handleShortlist={handleShortlist}
                    />
                </Fragment>
            );
        });
        return <ul className="list-unstyled">{profiles}</ul>;
    }
}

const reduxConnector = connect((state) => ({
    userDomain: state.authed.user.domain,
}));
export default reduxConnector(ProfileList);
