import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { renderField, renderTextAreaField, renderSelectField } from '../utils/ReduxFormUtils';

import { getWorkProfile } from '../actions/RegisterActions';
import { EDUCATION, PROFESSION, INCOME } from '../constants/Constants';

export const validate = (values) => {
    const errors = {};
    if (!values.education) {
        errors.education = 'Required';
    }
    if (!values.educationDesc) {
        errors.educationDesc = 'Required';
    }
    if (!values.profession) {
        errors.profession = 'Required';
    }
    if (!values.annualIncome) {
        errors.annualIncome = 'Required';
    }
    return errors;
};

class WorkForm extends Component {
    componentWillMount() {
        const { load } = this.props;
        if (load) {
            load();
        }
    }

    moveBack = () => {
        const { match, moveBack } = this.props;
        const { params } = match;
        moveBack({ currentPage: 'work', mode: params.mode });
    };

    render() {
        const { handleSubmit, submitting } = this.props;
        return (
            <div className="row">
                <div className="heading text-center">
                    <h3>Step 3/6: Add your education and work info</h3>
                </div>
                <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="col-md-10 col-md-offset-1">
                        <div className="row">
                            <div className="col-md-12 col-xs-12 marb10">
                                <Field name="education" className="form-control" component={renderSelectField} label="Education Qualification">
                                    <option value="">Choose</option>
                                    {EDUCATION.map((e, i) => (
                                        <option key={i} value={e}>
                                            {e}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-xs-12 marb10">
                                <Field
                                    name="educationDesc"
                                    className="form-control"
                                    placeholder="Education Description"
                                    component={renderTextAreaField}
                                    label="Education Description"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 marb10">
                                <Field name="profession" className="form-control" component={renderSelectField} label="Profession">
                                    <option value="">Choose</option>
                                    {PROFESSION.map((p, i) => (
                                        <option key={i} value={p}>
                                            {p}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                            <div className="col-md-6 col-xs-12 marb10">
                                <Field name="annualIncome" className="form-control" component={renderSelectField} label="Annual Income">
                                    <option value="">Choose</option>
                                    {INCOME.map((c, i) => (
                                        <option key={i} value={c}>
                                            {c}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 marb10">
                                <Field
                                    name="position"
                                    className="form-control"
                                    component={renderField}
                                    type="text"
                                    label="Position"
                                    placeholder="Your work designation"
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 marb10">
                                <Field
                                    name="workLocation"
                                    className="form-control"
                                    component={renderField}
                                    type="text"
                                    label="Work Location"
                                    placeholder="Your work city/town"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="onboard-next-container col-xs-12 text-right">
                        <button type="submit" className="btn btn-primary save onboard-next-button" disabled={submitting}>
                            {submitting ? 'Submitting...' : 'Update Work'}
                        </button>
                        <button className="btn btn-default onboard-back-button pull-left" onClick={this.moveBack}>
                            Back
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

const reduxFormDecorator = reduxForm({
    form: 'workProfile',
    validate,
});

// You have to connect() to any reducers that you wish to connect to yourself
const reduxConnector = connect(
    state => ({
        initialValues: state.registration.work, // pull initial values from account reducer
    }),
    { load: getWorkProfile }, // bind account loading action creator
);

export default reduxConnector(reduxFormDecorator(WorkForm));
