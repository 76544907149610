import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';
import getDataTransferFiles from '../utils/getDataTransferFiles';

class PhotoUpload extends Component {
    constructor(props) {
        super(props);
        this.state = { file: null, unsaved: false };
    }

    onPhotoUpload = () => {
        if (this.props.onPhotoUpload) {
            this.props.onPhotoUpload();
            this.setState({ file: null, unsaved: false });
        }
    };

    onChange = (evt) => {
        const fileList = getDataTransferFiles(evt);
        const acceptedFiles = [];
        fileList.forEach((file) => {
            try {
                file.preview = window.URL.createObjectURL(file); // eslint-disable-line no-param-reassign
            } catch (err) {
                if (process.env.NODE_ENV !== 'production') {
                    console.error('Failed to generate preview for file', file, err); // eslint-disable-line no-console
                }
            }
            acceptedFiles.push(file);
        });
        if (acceptedFiles.length > 0) {
            this.setState({ file: acceptedFiles[0], unsaved: true });
            this.props.handleUpload(acceptedFiles).then(() => {
                this.onPhotoUpload();
            });
        }
    };

    render() {
        const { unsaved } = this.state;
        return (
            <div className="file-upload" style={{ margin: 20 }}>
                {this.state.file && this.state.file.preview && (
                    <figure>
                        <img alt="Upload" src={this.state.file.preview} />
                    </figure>
                )}
                <label className="btn btn-primary">
                    Choose your photo to upload...
                    <input type="file" accept="image/png, image/jpeg, image/gif" multiple="false" onChange={this.onChange} />
                </label>
                <Prompt when={unsaved} message="Your photo upload is in-progress, are you sure you want to leave this page?" />
            </div>
        );
    }
}

export default PhotoUpload;
