import React from 'react';
import { connect } from 'react-redux';

import { verifyEmail } from '../actions/AuthedActions';
import Spinner from './Spinner';

class VerifyEmail extends React.Component {
    componentWillMount() {
        const { verify, match } = this.props;
        const { params } = match;
        verify({ user_id: params.userId, secret: params.secret });
    }

    render() {
        const { isEmailVerified } = this.props;
        let children = <Spinner />;
        if (isEmailVerified === true) {
            children = <div className="alert alert-success">Your email has been verified successfully.</div>;
        } else if (isEmailVerified === false) {
            children = <div className="alert alert-warning">Couldn't able to verify your email.</div>;
        }
        return <div className="row">{children}</div>;
    }
}

const mapStateToProps = state => ({
    isEmailVerified: state.authed.isEmailVerified,
});

const mapDispatchToProps = dispatch => ({
    verify: (d) => {
        dispatch(verifyEmail(d));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VerifyEmail);
